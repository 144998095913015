import React from 'react'
import { Container, Row, Col, Card, CardBody, CardTitle, CardFooter, Carousel, CarouselItem } from 'reactstrap';
import { portfolioData } from '../pages/portfolio/Portfolio'
import Helmet from 'react-helmet';

export type TestimonialsProps = {
    backgroundUrl?: string
}
export function Testimonials(props: TestimonialsProps) {
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [animating, setAnimating] = React.useState(false);
    const onExiting = () => {
        setAnimating(true);
    };
    const onExited = () => {
        setAnimating(false);
    };
    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };
    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    let bg = "/assets/images/testimonials-bg.jpg";
    if (props.backgroundUrl) {
        bg = props.backgroundUrl
    }

    const items: any[] = [];
    portfolioData.forEach(
        (protfolio, index) => {
            if (protfolio.testimonial.length > 5  ) {
                items.push(
                    {
                        src: { index },
                        altText: "",
                        caption: "",
                        content: (
                            <Card className="card-testimonial card-plain">
                                <div className="card-avatar">
                                    <a href="#pablo" onClick={e => e.preventDefault()}>
                                        <img
                                            alt="..."
                                            className="img img-raised rounded"
                                            src={protfolio.thumbUr}
                                        ></img>
                                    </a>
                                </div>
                                <CardBody>
                                    <h5 className="card-description">
                                        {protfolio.testimonial}
                                    </h5>
                                    <CardTitle tag="h3">{protfolio.author}</CardTitle>
                                    <CardFooter>
                                        <h6 className="category text-info">{protfolio.title}</h6>
                                    </CardFooter>
                                </CardBody>
                            </Card>
                        )
                    }
                )
            }

        }
    )

    return (
        <section style={{ background: `url(${bg})`, backgroundSize: "cover", backgroundPosition: 'center', padding: '100px 0 50px 0' }}>
            <Container>
                <Row style={{ textAlign: 'center' }}><h3 style={{ textTransform: 'uppercase', width: '100%' }}>What <span style={{ color: '#2c8e25', fontWeight: 600 }}>OUR Clients</span> say  </h3></Row>
                <Row>
                    <Col>
                        <Carousel
                            activeIndex={activeIndex}
                            next={next}
                            previous={previous}
                            style={{ boxShadow: 'none' }}
                        >
                            {items.map(item => {
                                return (
                                    <CarouselItem
                                        onExiting={onExiting}
                                        onExited={onExited}
                                        key={item.src}
                                        style={{ boxShadow: 'none' }}
                                    >
                                        {item.content}
                                    </CarouselItem>
                                );
                            })}
                            <a
                                className="carousel-control-prev"
                                data-slide="prev"
                                href="#pablo"
                                onClick={e => {
                                    e.preventDefault();
                                    previous();
                                }}
                                role="button"
                            >
                                <i className="now-ui-icons arrows-1_minimal-left"></i>
                            </a>
                            <a
                                className="carousel-control-next"
                                data-slide="next"
                                href="#pablo"
                                onClick={e => {
                                    e.preventDefault();
                                    next();
                                }}
                                role="button"
                            >
                                <i className="now-ui-icons arrows-1_minimal-right"></i>
                            </a>
                        </Carousel>

                    </Col>
                </Row>
            </Container>
            <Helmet>
                <style>
                    {
                        ` 
                    .carousel .carousel-inner {
                        box-shadow: none
                    } 
                    `
                    }
                </style>
            </Helmet>
        </section>
    )
}