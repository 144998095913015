import React from 'react'
import IndexHeader from '../../../components/IndexHeader'
import { StaticMasterTemplate } from '../StaticMasterTemplate'
import { Container, Row, Col } from 'reactstrap'
import { ReadMore } from '../../../components/ReadMore'
import { TemplateClientContent } from '../client-content/TemplateClientContent'
export type AustralianSeoServicesProps = {}
export function AustralianSeoServices(props: AustralianSeoServicesProps) {
    let header = <IndexHeader />
    return (
        <>
            <StaticMasterTemplate benner={header} >
                <section style={{ background: 'rgb(32, 39, 62)', padding: '50px 0' }}>
                    <Container>
                        <Row>
                            <Col>
                                <h1 style={{ textAlign: 'center' }}>Australian SEO Services</h1>
                                <p style={{ fontWeight: 300 }}>First impressions are everything. Your company’s website is often the first introduction between your company and potential customers. You need a stunning, functional website that represents who you are, is easy to read, and that works seamlessly. Done well, a website is a very powerful tool.  But not only that, your website must be searchable and able to be found, in the world’s top search engines. It also must be adaptable for desktop and mobile, where 80% of web searches take place. Your customers need to be able to find you, quickly, and in the ease of the palms of their hands. My Stores and More are the experts for <strong>Australia web design</strong> and <a href="/services/online-marketing/">Search Engine Optimization</a> . They have a portfolio of clients throughout Australia, with a range of brilliant, contemporary website design, and getting those websites to the top of the charts on Google Search, Page 1.  My Stores and More provide full service solutions and tailored packages  for <strong>web design Australia</strong>, including SEO, digital and social media marketing.  Contact us today to start building your brand and getting your website out there where it needs to be – Page 1.</p>
                                <ReadMore>
                                    <p>Deciding on web design and a website developer is one of the most important tasks for your marketing. Here are a few things to keep in mind:</p>
                                    <p>If you are like most entrepreneurs, you have most likely invested thousands of dollars into setting up your business, not to mention the countless hours of time.  Why not make the same level of investment into your most important marketing tool?  Investing early into the right solutions will save you money and time and a great deal of frustration in the long run. We have seen numerous cases where new businesses have started with a fast, cheap solution, just to gain a quick online presence, but within 12 months they are back to Square One, and making up for lost time. A quick money-saver at the beginning can cost months of potential income, not to mention the valuable time needed to get it right.  Set yourself up with the right partner to grow your business and online presence. My Stores and More has a proven track record with quality work and web design, and have worked with some of the largest names in Australia.  Websites are set up once, and deliver on results and metrics almost immediately.</p>
                                    <p>It’s never been easier to develop an idea into a business. That being said, there has never been more competition for new businesses. Just because you have a website and you share it with your friends and network doesn’t mean that it will be seen by your target customers. If you are not appearing on Page 1 of Google Search, you are reaching a very small portion of your target audience.  The best entrepreneurs know that brands aren’t built overnight.  It takes a great deal of time, effort, and a bit of money never hurts either. Our clients’ success is our success. With our quality solutions with SEO, <a href="/services/content-writing/">content writing</a> and keywords and we have seen many of our clients reach Page 1 on Google, and literally turn their business around.</p>
                                    <p>When it comes to <strong>Australia web design</strong>, My Stores and More are the industry leaders, with years of experience growing some of the biggest names in Australia’s businesses.  By choosing My Stores and More as your <strong>Australia web designer</strong>, you are choosing a partner to help you grow your online exposure and get more qualified leads and conversions.  No matter what industry you are in, your website must be visually appealing to customers, work quickly with all necessary information, and reflect the character of your business.  When we combine a stunning web design and an effective online marketing strategy, it is an unbeatable, proven formula for success.</p>
                                    <p>My Stores and More’s results-driven packages combine contemporary web design, a keyword focused SEO strategy, and cost-effective, engaging online marketing campaigns.  All together, they create a streamlined, measurable way to build your business. First, you must get the design right, and capture customers’ attention and entice them to stay on your website.  They must be able to navigate your site easily, and follow calls-to-action. The longer a customer is engaged by your site, the more likely you are to convert their interest into a lead, enquiry or sale. My Stores and More builds every detail of your site, with the customer in mine, from landing pages to specific campaigns.</p>
                                    <p>A major player on the My Stores and More team is our division of <a href="/services/creative-design/">graphic design</a> specialists. They collaborate to deliver sensational individualised products, the best in <strong>web design Australia</strong>. They understand they are building an image for your business, to introduce you to potential customers.  Designs are eye-catching, but our team also work with back-end technical staff from the start.  This means that your site is built with search in mind. So not only will the site look stunning on launch, but it is already meant to be found, getting noticed by Google.  Your website is both appealing visually, loads quickly, and is among the best for functionality and content.  This process for <strong>web design</strong> is unique to My Stores and More, and we do it better than anyone. We build sites with Search in mind from the start of the design process, rather than modifying after the site is built.   Results always take time, but from the moment we launch your site, you can watch as your rankings reach higher and higher!</p>
                                    <p>We guarantee that we will build your site to get you noticed on Google. You can see our list of clients who reached Page 1.  But you must understand that SEO isn’t a singular task, or an overnight process.  It has multiple facets, which include building your domain authority so that Google knows your content is worthy of the Page 1 spot.  We can let our work speak for itself.  SEO is about longevity, it’s an early investment that pays off returns in the long-run.  It’s measurable, and you can see the results. Why let your competitors outrank you?  You are the best at what you do, so your customers should find you first!</p>
                                    <p>Social media has changed the way we communicate and the way in which businesses connect with their customers. It’s so easy to send enquiries and questions through social media, the responses are immediate.  You need to  harness the power of social media. My Stores and More can do this for you.  We will design and run Facebook campaigns that target the right people for you and your business. We create specially designed ads, aimed at a target audience. So you know you’re effectively and efficiently reaching potential new customers. Once it’s working, we are able to scale this campaign, or redo it at various times, while your leads continue to pile up!</p>
                                    <p>You’ve heard of Google Pay Per Click, and you see it every day when you search in Google.  It’s the best way to get  yourself on the top of the page 1 instantly.  It is not easy, since nowadays everyone thinks they can do it.  Our method is complex, and we run the software and analytics for you, which regularly sharing results and insights. Our marketing packages are data driven, and proven to work. Google PPC  can be a great option to drive traffic to your website, qualified search leads, while your SEO strategy is building your organic ranking.  We research the most valuable search terms to match your brand, and also your competitors. With data, we reduce the element of chance.  The insights from our campaigns tell us exactly how we are doing, and if there is anything we need to change.</p>
                                    <p>A major part of the My Stores and More package is <strong>copy and content writing</strong>.  It is vital that your get your message across clearly, and in a tone that connects immediately with your customers. Dynamic, persuasive and engaging copywriting creates a voice for your business, it builds trust, and this is what brings customers to become sales. This is the unique voice for your business. Providing insightful content, while educating customers on what you do and why you are the best, will increase customer engagement on your site as well as your conversions.  Your website must not be one-sided, it must engage the customer to act – with calls to action, hot buttons, and great content.  We do all of those for you!  My Stores and More works with experienced copy writers, whose main goal is to deliver your message, engage customers on your site, and also get noticed by the Google content searches.</p>
                                    <p>You need an online presence, and you need to be found by Google Search and your audience. Your website is the most valuable tool you can have. Don’t waste time going with an option that may save you money now.  Make a solid investment in the future of your business, and partner with the best, all-service solution, to get noticed by Google and a wealth of potential customers.</p>
                                </ReadMore>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section style={{ padding: '50px 0' }}>
                    <Container>
                        <Row>
                            <Col>
                                <h4 style={{ textAlign: "center", marginTop: 0 }}>We work with businesses to help them grow from an idea to a successful business through hard work, investment in a great website and a marketing plan that works every time. Check out our work for some of our clients:</h4>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <TemplateClientContent
                    style={{ background: 'rgb(32, 39, 62)', padding: '50px 0 0 0 ' }}
                    imageUrl="/assets/images/client-png/robert-up-ward-aus-img.png"
                    imageAltText="UPWARD Studios"
                    imagePosition="LEFT"
                    textPaddingTop={20}
                >
                    <h3>UPWARD Studios</h3>
                    <p>Upward Studio is a boutique recording studio based in Melbourne. They compose music for a long list of high profile artists as well as music for movies and jingles for TV and radio. They needed a website that would show potential clients the high level of music production they do and a marketing program that would attract more clients.</p>
                    <p>After a successful website completion, we embarked on a national SEO campaign that saw them achieve many page 1 positions in Google. Over the years the success of the SEO campaign has meant hundreds of thousands in sales as they achieved national exposure.</p>
                </TemplateClientContent>
                <TemplateClientContent
                    style={{ background: 'url("/assets/images/client-png/bg1.jpg"', backgroundPosition: 'top', backgroundSize: 'cover', padding: '50px 0 0 0 ' }}
                    imageUrl="/assets/images/client-png/ray-white.png"
                    imageAltText="ray-white"
                    imagePosition="RIGHT"
                    textPaddingTop={20}
                >
                    <h3>Ray White</h3>
                    <p>Ray White Real Estate has very specific requirements to fill rental properties in regional parts of Australia.</p>
                    <p>We built a series of landing pages on their website and targeted client via social media with high end ads that stood out on Instagram and Facebook.</p>
                    <p>Over a 4 months campaign we achieved the targeted number of rental customers needed to fulfill their back log of vacant homes.</p>
                </TemplateClientContent>
                <TemplateClientContent
                    style={{ padding: '50px 0 0 0 ' }}
                    imageUrl="/assets/images/client-png/fhb.png"
                    imageAltText="First Home Boost"
                    imagePosition="LEFT"
                    textPaddingTop={20}
                >
                    <h3>First Home Boost</h3>
                    <p>First Home Boost required additional performance fast as their current marketing agency was not hitting the required targets of lead generation for their first home buyers’ market.</p>
                    <p>We redesigned targeting options included retargeting add production to increase click through rates and lower the cost of lead generation.</p>
                    <p>We then redesigned landing pages, sales scripts, add copy, landing pages and imagery.</p>
                    <p>The result has been a 600% increase in leads.</p>
                </TemplateClientContent>
                <TemplateClientContent
                    style={{ background: 'url("/assets/images/client-png/bg2.jpg"', backgroundPosition: 'top', backgroundSize: 'cover', padding: '30px 0 50px 0 ' }}
                    imageUrl="/assets/images/client-png/hire-style.png"
                    imageAltText="Hire Style"
                    imagePosition="RIGHT"
                    textPaddingTop={20}
                >
                    <h3>Hire Style</h3>
                    <p>Hirestyle are a prestigious event planning website and have a very diverse range of party hire equipment for weddings and special events. They needed a website that portrayed the range of party hire equipment along with exposure highlighting the events they worked with.</p>
                    <p>Hirestyle also need to increase their market share through a Melbourne based Google Pay Per Click and SEO campaign.</p>
                    <p>Within days we had additional leads flooding in for parties and events and soon followed strong page 1 rankings for targeted keywords.</p>
                </TemplateClientContent>
                <TemplateClientContent
                    style={{ background: 'rgb(32, 39, 62)', padding: '0' }}
                    imageUrl="/assets/images/client-png/calibre-clinic.png"
                    imageAltText="Calibre Clinic"
                    imagePosition="LEFT"
                    textPaddingTop={20}
                >
                    <h3>Calibre Clinic</h3>
                    <p>Dr Jayson Oates needed the latest in website design for his new medical procedure. Dr Jayson needed a strong online presence and high profile website to assist in getting the procedure to market quickly and a website that would work hand in hand with the national PR release that was happening with TV and Radio.</p>
                    <p>We also design a strong SEO campaign that would assist in national growth.</p>
                    <p>Within months we had achieved many high rankings page 1 positions in Google through high end medical based content and referring medical websites.</p>
                    <p>In November 2018 we won the “Best Medical Website” for Australasia.</p>
                </TemplateClientContent>
                 
                <TemplateClientContent
                    style={{ background: 'url("/assets/images/client-png/bg1.jpg"', backgroundPosition: 'top', backgroundSize: 'cover', padding: '50px 0 0 0 ' }}
                    imageUrl="/assets/images/client-png/ray-white.png"
                    imageAltText="Sydney Property Repair"
                    imagePosition="RIGHT"
                    textPaddingTop={20}
                >
                    <h3>Sydney Property Repair</h3>
                    <p>Sydney Property repair need a functional website for local based trade jobs. The owner was moving from a full time job to running his own business so it was crucial the website was performing fast and producing leads as quickly as possible.</p>
                    <p>A strong local based SEO campaign meant they had a good source of leads coming in quickly and the owner could concentrate on earning money and not losing focusing on understanding technology that slowed down his day to day business.</p>
                </TemplateClientContent>
                <TemplateClientContent
                    style={{ padding: '50px 0 0 0 ' }}
                    imageUrl="/assets/images/client-png/Sea-Vu-Play-Bali.png"
                    imageAltText="Sea Vu Play Restaurant"
                    imagePosition="LEFT"
                    textPaddingTop={100}
                >
                    <h3>Sea Vu Play Restaurant</h3>
                    <p>Sea Vu Play has recently undertaken renovations for expansion and needed customers fast! We undertook a full website rebuild including strong content, fresh look and feel and calendar for events.</p>
                    <p>We then focused on SEO for restaurant, dining and nightlife keywords. We then included a strong social media campaign.</p>
                    <p>Within a few short weeks we had increased sales and within a few months had the website ranking for all targeted keywords to ensure a constant influx of new and ongoing customers.</p>
                </TemplateClientContent>
                <TemplateClientContent
                    style={{ background: 'url("/assets/images/client-png/bg2.jpg"', backgroundPosition: 'top', backgroundSize: 'cover', padding: '30px 0 70px 0 ' }}
                    imageUrl="/assets/images/client-png/amo-spa-bali-1.png"
                    imageAltText="AMO Spa and Salon"
                    imagePosition="RIGHT"
                    textPaddingTop={20}
                >
                    <h3>AMO Spa and Salon</h3>
                    <p>AMO Spa and Salon was in the midst of a strength growth phase when their friend told them to work with My Stores And More. The owners need dynamic pricing and online booking system that we coded to work for end users allowing them to book via multiple locations.</p>
                    <p>We then embarked on an SEO campaign that within months saw an increase in customers by over 200 new clients each month!</p>
                    <p>We also added a Social media campaign to ensure we were attracting new clients from not only Google but Facebook and Instagram also.</p>
                </TemplateClientContent>
                <TemplateClientContent
                    style={{ background: 'rgb(32, 39, 62)', padding: '0' }}
                    imageUrl="/assets/images/client-png/academy-face-and-body.png"
                    imageAltText="Academy Face and Body"
                    imagePosition="LEFT"
                    textPaddingTop={20}
                >
                    <h3>Academy Face and Body</h3>
                    <p>Academy Face And Body not only needed a new look to ensure there online reputation was as strong as the cosmetic team that worked for them but also to re gain their presence in the local market as competitors has been investing strongly on their online brands</p>
                    <p>They had also had a number of different SEO teams work on their project over the years with little or no results.</p>
                    <p>We rebuilt their content with a very strong focus on high end revering medical websites in Australia and globally.</p>
                    <p>Within months we had over 30 targeted keywords on page 1 of Google delivery more leads and sales</p>
                </TemplateClientContent>
                

                <TemplateClientContent
                    style={{ background: 'url("/assets/images/client-png/bg1.jpg"', backgroundPosition: 'top', backgroundSize: 'cover', padding: '50px 0 0 0 ' }}
                    imageUrl="/assets/images/client-png/kuta-height-hi-res.png"
                    imageAltText="Kuta Heights"
                    imagePosition="RIGHT"
                    textPaddingTop={20}
                >
                    <h3>Kuta Heights</h3>
                    <p>Kuta Heights Development have over 200 prime beach view land parcels for sale. However, they started their website on a DIY platform and after months of zero traction realized they were nowhere to be seen in Google.</p>
                    <p>We designed the latest in lead capturing Google ads and a strong SEO campaign with high quality real estate and news backlinks through press release to gain momentum and visibility as quickly as possible.</p>
                    <p>The above marketing with a beautifully designed website ensured leads coming through within the first week of launch.</p>
                </TemplateClientContent>
                <TemplateClientContent
                    style={{ padding: '50px 0 0 0 ' }}
                    imageUrl="/assets/images/client-png/bali-hotels-and-more.png"
                    imageAltText="Bali Hotels and More"
                    imagePosition="LEFT"
                    textPaddingTop={100}
                >
                    <h3>Bali Hotels and More</h3>
                    <p>Bali Hotels And More is a customized website coded by our inhouse team of high end engineers. Bali Hotels have over 2000 hotels online with instant and dynamic pricing and online booking.</p>
                    <p>The website took 6 months to build and since launch has achieved over 1 million dollars in online and offline sales.</p>
                    <p>With a global marketing campaign consisting of SEO, Google PPC and Social media</p>
                    <p>The website competes with such global brands as Booking.com and Airbnb.</p>
                </TemplateClientContent>
                <TemplateClientContent
                    style={{ background: 'url("/assets/images/client-png/bg2.jpg"', backgroundPosition: 'top', backgroundSize: 'cover', padding: '30px 0 70px 0 ' }}
                    imageUrl="/assets/images/client-png/loud-crawd-music.png"
                    imageAltText="Loud Crowd Music"
                    imagePosition="RIGHT"
                    textPaddingTop={20}
                >
                    <h3>Loud Crowd Music</h3>
                    <p>Loud Crowd Music creates jingles and custom music for Australia’s leading commercial brands. We built a high-end website that would attract the best advertising agencies and Australia top retail brands.</p>
                    <p>We also selected top keywords that the industry uses to find companies like Loud Crowd Music. Within a few short months we had the website ranking high in Google.</p>
                </TemplateClientContent>

                <TemplateClientContent
                    style={{ background: 'rgb(32, 39, 62)', padding: '0' }}
                    imageUrl="/assets/images/client-png/bali-tours-and-more.png"
                    imageAltText="Bali Tours and More"
                    imagePosition="LEFT"
                    textPaddingTop={20}
                >
                    <h3>Bali Tours and More</h3>

                    <p>As a global tour provider for Bali, Bali Tours And More needed a website with attractive design and online booking system that worked in multiple currencies.</p>
                    <p>They also needed a social media, Google PPC and Google SEO campaign that allowed top page 1 results on a global basis.</p>
                    <p>With over 30,000 words of content we had delivered their requirements within 2 short months.</p>
                    <p>Bali Tours And More now rank globally and enjoy daily online bookings from their website achieving strong ROI from online marketing.</p>
                </TemplateClientContent>
                <TemplateClientContent
                    style={{ background: 'rgb(32, 39, 62)', padding: '50px 0 0 0' }}
                    imageUrl="/assets/images/client-png/bali-villas-and-more.png"
                    imageAltText="Bali Villas and More"
                    imagePosition="RIGHT"
                    textPaddingTop={0}
                >
                    <h3>Bali Villas and More</h3>
                    <p>Bali Villas And More have more than 1000 villas listed on their website. They have built this over the last 2 years with the direct involvement of My Stores And More as we have worked with them to match their growth.</p>
                    <p>We have been instrumental in developing marketing campaign in social media, Google PPC and SEO on a global level.</p>
                    <p>We assist in database management and website design and growth and help them understand where customers are coming from and how to increases sales on a monthly basis.</p>
                </TemplateClientContent>
                <TemplateClientContent
                    style={{ background: 'rgb(32, 39, 62)', padding: '50px 0 0 0' }}
                    imageUrl="/assets/images/client-png/academy-ladies-clinic.png"
                    imageAltText="Academy Ladies Clinic"
                    imagePosition="LEFT"
                    textPaddingTop={0}
                >
                    <h3>Academy Ladies Clinic</h3>
                    <p>Academy Laser Clinics are Sydney’s leading laser clinic. They needed a full website rebrand and a strong PPC and SEO campaign.</p>
                    <p>We worked with the team to find highly target keywords with the right bidding strategy to reduce Google monthly costs.</p>
                    <p>Within 6 months we had targeted keywords ranking on Page 1 of Google and a high-performance website that delivered leads into sales month after month.</p>
                </TemplateClientContent>
            </StaticMasterTemplate>
        </>
    )
}