import React from 'react'
import { Container, Row, Col } from 'reactstrap'
export type DownloadAtoZProps = {
    style?: React.CSSProperties 
}
export function DownloadAtoZ(props: DownloadAtoZProps) {
     
    return (
        <section style={props.style ? props.style : {}}>
            <Container>
                <Row>
                    <Col style={{ padding: "100px 0 100px 0" }} md={6}>
                        <h3>Download The A to Z Web Guide<br />
                            All The Information You Will Ever Need To Build A Website</h3>
                        <a className="btn-round btn btn-info btn-lg" rel="noopener noreferrer" href="https://storage.googleapis.com/mystoresandmore/website-eBook-MSAM-2.pdf" target="_blank">Download Now</a>  <a className="btn-round btn btn-info btn-lg" href="/contact-us">Get Free Quote</a>
                    </Col>
                    <Col md={6} style={{ textAlign: "center" }}>

                        <img src="/assets/images/A5-horizontal-Brochure-Cover.jpg" alt="" />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}