import React from 'react'
import { Card, CardBody, CardTitle, CardFooter } from 'reactstrap'
import { portfoliosCategory } from './Portfolio'
export type PortfolioCardProps = {
    title: string,
    imageUrl: string,
    thumbUr: string,
    categories: portfoliosCategory[],
    works: string[],
    content: any;
    author: string,
    testimonial: string,
    onClick: (cat: string) => any
}
export function PortfolioCard(props: PortfolioCardProps) {

    let work = ""
    let loop = 0
    for (let x of props.works) {
        if (loop > 0) {
            work += ", "
        }
        work += x
        loop++
    }

    return (
        <Card className="card-blog" style={{cursor: 'pointer'}} onClick={() => props.onClick(props.title)}>
            <div className="portfolio-list-image" style={{ paddingTop: '75%', backgroundSize: 'cover', background: `url("${props.thumbUr}") center center` }}>
            </div>
            <CardBody>
                <CardTitle tag="h5" style={{color: '#0f0f0f'}}> 
                        {props.title} 
                </CardTitle>
                <p className="card-description">
                    {
work
                    }
                </p>
                <CardFooter>
                    <div className="stats stats-right">
                        <i className="now-ui-icons users_circle-08"></i>
                        <span style={{ color: '#0f0f0f' }}>{props.author}</span>
                    </div>

                </CardFooter>
            </CardBody>
        </Card>
    )
}