import React from 'react'
import IndexNavbar from '../../../components/IndexNavbar'
import { Footer } from '../../../components/Footer'
import {Helmet} from 'react-helmet'
import CreativeDesignHeader from './CreativeDesignHeader'
import { Container, Row, Col, Card, CardHeader, CardBody } from 'reactstrap'
export type CreativeDesignProps = {}
export function CreativeDesign(props: CreativeDesignProps) {
    return (
        <>
        <Helmet>
        <title>Online Marketing | My Stores and More</title>
    <meta name="description" content="Complete Online Marketing and Brand Solutions"/>
        </Helmet>
            <IndexNavbar />
            <div className="wrapper">
                <CreativeDesignHeader />
                <div className="main">
                    <section style={{ background: 'rgb(32, 39, 62)', padding: '0 0 50px 0' }}>
                        <Container>
                            <Row>
                                <Col md={2}></Col>
                                <Col md={2}>
                                    <img style={{marginTop: '20%'}} src="/assets/images/logo-white.png" alt="" />
                                </Col>
                                <Col md={7}>
                                    <h5>Bespoke Creative Design Solutions That Make Your Business Stand Out </h5>
                                    <p>Creative designs that look great and promote your brand are the trademark of the My Stores and More Design Team. Because we know the best results come from team efforts, our designers work alongside our marketing experts and technical staff. This means that as well as looking great, we’ll provide designs that are also functional and effective, increasing your sales.</p>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section className="features-6" style={{  padding: ' 50px 0', color: '#fff' }}>
                        <Container>

                            <Row>
                                <Col md={4}>
                                    <div className="info info-horizontal" style={{padding: 0}}>
                                        <div className="icon icon-info">
                                            <i className="now-ui-icons design-2_html5"></i>
                                        </div>
                                        <div className="description">
                                            <h5 className="info-title">Brochure Design </h5>
                                            <p>Design consistency builds customers trust. Linking the design of your online presence with brochures or other publicity and marketing materials builds your brand and builds customer confidence. Our team of designers will connect your online image to your brochure, ensuring visually stunning and commercial effective marketing materials.</p>
                                        </div>
                                    </div>
                                    <div className="info info-horizontal">
                                        <div className="icon icon-danger">
                                            <i className="now-ui-icons design_palette"></i>
                                        </div>
                                        <div className="description">
                                            <h4 className="info-title">Branding</h4>
                                            <p>Your brand is your reputation, and your reputation is the best marketing you can have. My Stores and More will work with you to ensure all of your company materials look as professional as the services and products you provide. Stylized and individualized branding that captures the unique essence of your business makes sure that you look as good as your reputation says you are, build trust and builds sales.</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="phone-container">
                                        <img alt="..." src={"/assets/images/iphone2.08746fe1.png"}></img>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="info info-horizontal"  style={{padding: 0}}>
                                        <div className="icon icon-primary">
                                            <i className="now-ui-icons design-2_ruler-pencil"></i>
                                        </div>
                                        <div className="description">
                                            <h5 className="info-title">Logo Design</h5>
                                            <p>An effective logo captures the essence of what you do, making you instantly recognizable as purveyors of quality in your field. Our designers will work with you on either business or illustration logo design to ensure you have the stylish and contemporary logo that’s right for both you and your customers. A stunning logo is the cornerstone to building your brand effectively.</p>
                                        </div>
                                    </div>
                                    <div className="info info-horizontal">
                                        <div className="icon icon-success">
                                            <i className="now-ui-icons files_single-copy-04"></i>
                                        </div>
                                        <div className="description">
                                            <h4 className="info-title">Corporate Profile </h4>
                                            <p>We know you’re a serious business, and so do you. How do your customers know? Building on our Branding Services, My Stores and More will develop your complete corporate profile. Does sending out a quote for thousands of dollars as a simple email give your customers the right message? We’ll provide you with quote templates, image email signatures and anything else you need to make sure that everything you do builds trusts and improves conversions.</p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

</Container>
</section>
<section style={{ background: 'rgb(32, 39, 62)', padding: '0 0 50px 0' }}>
                        <Container>
                            <Row>
                                <Col md={12} style={{marginTop: 50}}>
                                    <h3 style={{ textAlign: "center", color: "rgb(63, 223, 20)", fontWeight: 800 }}><span style={{color: '#fff', fontWeight: 300}}>Your Questions</span> Answered</h3>
                                </Col>
                                <Col md={6}>
                                    <Card className="card-plain">
                                        <CardHeader style={{color: "rgb(63, 223, 20)", fontSize: 18}} id="headingOne" role="tab">
                                        Why is creative design important?  
                                        </CardHeader> 
                                            <CardBody>
                                            Your marketing materials need to be of the same high quality standards as the products and services that your company offers. Of course your marketing materials need to look fantastic, but they also need to be functional. Creative design services ensure that your message is targeted to your audience in a way that will make them take notice, understand what you are about and engage.
                                            </CardBody> 
                                    </Card>
                                    <Card className="card-plain">
                                        <CardHeader  style={{color: "rgb(63, 223, 20)", fontSize: 18}}  id="headingOne" role="tab">
                                        Do I need to integrate the design of all my marketing materials?
                                        </CardHeader> 
                                            <CardBody>
                                            You need to build trust with your customer base and brand consistency is an important aspect if you really want to be taken seriously. While it may be tempting to keep on using the old flyers you have, this is probably a false economy that may cost you sales. Having consistent design themes across all of your materials is going to build trust in your brand – and increased trust means increased sales.

</CardBody> 
                                    </Card>
                                </Col>
                                <Col md={6}>
                                    <Card className="card-plain">
                                        <CardHeader   style={{color: "rgb(63, 223, 20)", fontSize: 18}}  id="headingOne" role="tab">
                                        Can I still include my design ideas? 
                                               
                                        </CardHeader> 
                                            <CardBody> Of course – nobody knows your business better than you do. At My Stores and More design is a collaborative process. We present you with a range of concepts, which can be original or include your design ideas. Your design ideas are combined with our marketing knowledge and experience to ensure that they are functional and appealing to your target audience. Creative design is effective design that engages customers to increase your conversions.</CardBody> 
                                    </Card>
                                    <Card className="card-plain">
                                        <CardHeader  style={{color: "rgb(63, 223, 20)", fontSize: 18}}  id="headingOne" role="tab">
                                        Does creative design really have an impact on my sales?                                            
                                        </CardHeader> 
                                            <CardBody>
                                            Absolutely. Creative design isn’t just about looking great; it’s about engaging customers to increase the time they spend on your site and building trust. My Stores and More creative design services combine aesthetics with functionality. We’re committed to making sure that your website provides a logical and customer friendly user experience. This unbeatable combination of looking fantastic and being fully functional calls customers to action and increases your conversions.</CardBody> 
                                    </Card>
                                </Col> 
                            </Row>

                        </Container>
                    </section>

                    <Footer />
                </div>
            </div>
        </>
    )
}