import React from 'react'
export type SocialMediaProps = {}
export function SocialMedia(props: SocialMediaProps) {
    return (
        <div >
            <ul className="social-buttons" style={{ fontSize: 28 }}> 
                <li><a href="https://www.facebook.com/mystoresandmore/" rel="noopener noreferrer" target="_blank" ><i className="fab fa-facebook-square"></i></a></li>
                <li><a href="https://www.youtube.com/channel/UCzuXYB7-IGx8U-4cd2MnHjg" ><i className="fab fa-youtube"></i></a></li>
                <li><a href="https://www.instagram.com/mystoresandmoredotcom/" ><i className="fab fa-instagram"></i></a></li>
            </ul>
        </div>
    )
}