import React from 'react'
import {Helmet} from 'react-helmet'
import IndexNavbar from '../../../components/IndexNavbar'
import { Footer } from '../../../components/Footer'
import OnlineMarkeringHeader from './OnlineMarkeringHeader'
import { Container, Row, Col, Card, CardHeader,  CardBody } from 'reactstrap'
export type OnlineMarkeringProps = {}
export function OnlineMarkering(props: OnlineMarkeringProps) {  
    return (
        <>
        <Helmet>
        <title>Online Marketing | My Stores and More</title>
    <meta name="description" content="Complete Online Marketing and Brand Solutions"/>
        </Helmet>
            <IndexNavbar />
            <div className="wrapper">
                <OnlineMarkeringHeader />
                <div className="main">
                    <section style={{ padding: '0 0 50px 0', background: '#1f283e' }}>
                        <Container>
                            <Row>
                                <Col md={2}></Col>
                                <Col md={2}>
                                    <img style={{margin: '20px 0'}} src="/assets/images/logo-white.png" alt="" />
                                </Col>
                                <Col md={7}>
                                    <h5>Expose Yourself to Qualified Leads With Our Online Marketing Solutions</h5>
                                    <p>My Stores and More Online Marketing solutions provide a personalized solution to your business needs. We increase your online presence to your target audience, increase leads, and ensure repeated traffic at a higher rate. This means increased conversions for you.</p>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section className="features-6" style={{  padding: ' 50px 0', color: '#fff' }}>
                        <Container>

                            <Row>
                                <Col md={4}>
                                    <div className="info info-horizontal">
                                        <div className="icon icon-info">
                                            <i className="now-ui-icons design-2_html5"></i>
                                        </div>
                                        <div className="description">
                                            <h5 className="info-title">Google Marketing</h5>
                                            <p> If you want your business to be seen by more potential customers then Google is still one of the strongest online solutions to get your business EXPOSED.  </p>
                                        </div>
                                    </div>
                                    <div className="info info-horizontal">
                                        <div className="icon icon-danger">
                                            <i className="now-ui-icons design_palette"></i>
                                        </div>
                                        <div className="description">
                                            <h4 className="info-title">Social Media Marketing</h4>
                                            <p>Social media has changed the way the world communicates across states and borders. We share information, recommendations and stories at lightening speed.  </p>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="phone-container">
                                        <img alt="..." src={"/assets/images/iphone2.08746fe1.png"}></img>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="info info-horizontal">
                                        <div className="icon icon-primary">
                                            <i className="now-ui-icons design-2_ruler-pencil"></i>
                                        </div>
                                        <div className="description">
                                            <h5 className="info-title">Search Engine Optimization</h5>
                                            <p>Is you want to build a business that will survive the test of time then Search Engine Optimization (SEO) absolutely must be on your marketing radar.  </p>
                                        </div>
                                    </div>
                                    <div className="info info-horizontal">
                                        <div className="icon icon-success">
                                            <i className="now-ui-icons files_single-copy-04"></i>
                                        </div>
                                        <div className="description">
                                            <h4 className="info-title">Email Marketing</h4>
                                            <p>Savy business owners agree that it’s easier and more cost effective to create additional sales from your existing customer base than it is to produce new sales. </p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section className="features-6" style={{ background: 'rgb(32, 39, 62)', padding: ' 50px 0', color: '#fff' }}>
                        <Container>
                            <Row>
                                <Col md={12} style={{marginTop: 50}}>
                                    <h3 style={{ textAlign: "center", color: "rgb(63, 223, 20)", fontWeight: 800 }}><span style={{color: '#fff', fontWeight: 300}}>Your Questions</span> Answered</h3>
                                </Col>
                                <Col md={6}>
                                    <Card className="card-plain">
                                        <CardHeader style={{color: "rgb(63, 223, 20)", fontSize: 18}} id="headingOne" role="tab">
                                             Why do I need an online marketing strategy?    
                                        </CardHeader> 
                                            <CardBody>
                                                You’ve got a visually stunning and functional website, but there are over a billion website on the internet. You need to make sure that people find yours. An effective online marketing strategy is essential in ensuring that you drive traffic to your site and provide qualified leads.
                                            </CardBody> 
                                    </Card>
                                    <Card className="card-plain">
                                        <CardHeader  style={{color: "rgb(63, 223, 20)", fontSize: 18}}  id="headingOne" role="tab">
                                             How do I control costs? 
                                        </CardHeader> 
                                            <CardBody>
                                            Marketing and lead generation costs are an integral part of any business plan. Like you, we understand that it’s vital to keep these costs under control. If you don’t know what you’re doing, pay per click adverts on sites such as Google and Facebook can prove costly and ineffective. My Stores and Mores combine experience with a data-drive, software-led approach to develop and scale your marketing campaigns to ensure cost effective, high volume lead generation.
                                            </CardBody> 
                                    </Card>
                                </Col>
                                <Col md={6}>
                                    <Card className="card-plain">
                                        <CardHeader   style={{color: "rgb(63, 223, 20)", fontSize: 18}}  id="headingOne" role="tab">
                                              How do you target my potential customers amongst the billions of internet users?{" "}
                                               
                                        </CardHeader> 
                                            <CardBody>
                                            At My Stores and More we use a research and data driven approach to targeting your marketing campaigns. Based around keyword searches, geographical areas, and users previous relevant engagement activity we ensure that your marketing and remarketing is aimed directly at the people looking for your products and services. We don’t just drive traffic to your site; we drive qualified traffic most likely to convert.
                                            </CardBody> 
                                    </Card>
                                    <Card className="card-plain">
                                        <CardHeader  style={{color: "rgb(63, 223, 20)", fontSize: 18}}  id="headingOne" role="tab">
                                             What forms of online marketing do you provide?
                                            
                                        </CardHeader> 
                                            <CardBody>
                                            My Stores and More provide your complete integrated online marketing solution. With Social Media, SEO, Google Advertising, video integration through YouTube, and email marketing campaigns, you can rest assured that you will have all bases covered. Your business will have effective online exposure that’s guaranteed to improve your sales.
                                            </CardBody> 
                                    </Card>
                                </Col> 
                            </Row>

                        </Container>
                    </section>


                    <Footer />
                </div>
            </div>
        </>
    )
}
