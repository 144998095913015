import React from 'react'
import { Col, Container, Row } from 'reactstrap'
export type HomeIndustriesProps = {}
export function HomeIndustries(props: HomeIndustriesProps) {
    const industryDatas = [
        { imageUrl: "/assets/images/ecommerce-icon-services.png", altText: "", content: "Hotels, Villas, Apparel, Homewares, Equipment Hire " },
        { imageUrl: "/assets/images/education-icon-services.png", altText: "", content: "Child Care, Online Learning, Schools" },
        { imageUrl: "/assets/images/financeial-icon-services.png", altText: "", content: "Accountant, Mortgage and Finance Brokers, Wealth Creation " },
        { imageUrl: "/assets/images/madical-icon-services.png", altText: "", content: "Hospital, Medical Center, Doctors, Therapist" },
        { imageUrl: "/assets/images/property-icon-services.png", altText: "", content: "Real Estate, Property Development" },
        { imageUrl: "/assets/images/tradespople-icon-services.png", altText: "", content: "Electrician, Handyman, Plumber, Carpenter" },
    ]
    let industries: any[] = []  
    for (let i of industryDatas) { 
        industries.push(
            <Col style={{ textAlign: 'center' }} md={2} sm={6}>  <img src={i.imageUrl} alt={i.altText} /> <p>{i.content}</p> </Col>
        ) 
    } 
    return (
        <section style={{ padding: '100px 0 50px 0' }}>
            <Container>
                <Row style={{ textAlign: 'center' }}><h3 style={{ textTransform: 'uppercase', width: '100%' }}>We Serve <span style={{ color: '#2c8e25', fontWeight: 600 }}>All Industries</span>  </h3></Row>
                <Row>
                    {industries}
                </Row>
            </Container>
        </section>
    )
}