import React from 'react'
import IndexNavbar from '../../components/IndexNavbar';
import IndexHeader from '../../components/IndexHeader';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { HomeIndustries } from './HomeIndustries';
import { Testimonials } from '../../components/Testimonials'
import { Footer } from '../../components/Footer'
import { GrowBusniness } from './GrowBusniness' 
import { OurProjects } from './OurProjects'
type HomePageProps = {}
export function HomePage(props: HomePageProps) {
    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });

    return (
        <>
            <IndexNavbar />
            <div className="wrapper">
                <IndexHeader />
                <div className="main">
                    <section style={{ background: 'rgb(32, 39, 62)', padding: '50px 0' }}>
                        <Container>
                            <Row>
                                <Col md={2}></Col>
                                <Col md={2}>
                                    <img src="/assets/images/logo-white.png" alt="" />
                                </Col>
                                <Col md={7}>
                                    <p style={{fontSize: 22}}>My Stores and More offer a complete suite of services for your online marketing success</p>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section style={{ padding: '100px 0 50px 0' }}>
                        <Container>
                            <Row style={{ textAlign: 'center' }}><h3 style={{ textTransform: 'uppercase', width: '100%' }}>Complete <span style={{ color: '#2c8e25', fontWeight: 600 }}>Online Marketing</span> and Brand Solutions</h3></Row>
                            <Row style={{ textAlign: 'center' }}>
                                <Col md={3} >
                                    <img src="/assets/images/online-marketing.png" alt="" />
                                    <h4 style={{ textAlign: 'center', textTransform: 'uppercase', color: '#00b8ff', fontWeight: 600 }}>Online Marketing</h4>
                                    <p>Our specialized marketing teams work to increase your conversions, repeat traffic, and expand your online visibility.</p>
                                    <p><Link to="/services/online-marketing">Read More</Link> </p>
                                </Col>
                                <Col md={6}>
                                    <img src="/assets/images/laptop-center.png" alt="" />
                                </Col>
                                <Col md={3}>
                                    <img src="/assets/images/content-writing.png" alt="" />
                                    <h4 style={{ textAlign: 'center', textTransform: 'uppercase', color: '#00b8ff', fontWeight: 600 }}>CONTENT WRITING</h4>
                                    <p>Professional content writing ensures the ultimate voice for your business, builds trust, increases engagement and encourages conversions</p>
                                    <p><Link to="/services/content-writing">Read More</Link> </p>
                                </Col>
                            </Row>
                            <Row style={{ textAlign: 'center' }}>
                                <Col md={3} >
                                    <img src="/assets/images/web-design.png" alt="" />
                                    <h4 style={{ textAlign: 'center', textTransform: 'uppercase', color: '#00b8ff', fontWeight: 600 }}>WEB DESIGN</h4>
                                    <p>Visually stunning and functional websites that get your business noticed online. </p>
                                    <p><Link to="/services/web-design">Read More</Link> </p>
                                </Col>
                                <Col md={6}>
                                    <Row>
                                        <Col md={3}></Col>
                                        <Col md={6}>
                                            <img src="/assets/images/createve-design.png" alt="" />
                                            <h4 style={{ textAlign: 'center', textTransform: 'uppercase', color: '#00b8ff', fontWeight: 600 }}>CREATIVE DESIGN</h4>
                                            <p>Our graphic design specialists will deliver bespoke design solutions to create the ultimate image for your business.</p>
                                            <p><Link to="/services/creative-design">Read More</Link> </p>
                                        </Col>
                                        <Col md={3}></Col>
                                    </Row>
                                </Col>
                                <Col md={3}>
                                    <img src="/assets/images/audio-video.png" alt="" />
                                    <h4 style={{ textAlign: 'center', textTransform: 'uppercase', color: '#00b8ff', fontWeight: 600 }}>MUSIC & VIDEO</h4>
                                    <p>Make your company and advertising campaign stand above the rest with the use of video production and custom music.</p>
                                    <p><Link to="/services/audio-video">Read More</Link> </p>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <OurProjects />
                    <Testimonials />
                    <HomeIndustries />
                    <GrowBusniness />
                    <Footer type="HOMEPAGE"/>
                </div>
            </div>
        </>
    )
}