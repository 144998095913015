import React from 'react';
import { MainPage } from './pages/MainPage';
import "./assets/css/bootstrap.min.css";
import "./assets/css/now-ui-kit.min.css";
import "./assets/scss/now-ui-kit.scss";
import "./assets/demo/nucleo-icons-page-styles.css";
import "./assets/demo/demo.css";
import "./assets/mycss.css";

export type mainContextType = {
  firebaseApp: firebase.app.App;
}
export const MainContext = React.createContext<mainContextType>({
  firebaseApp: null as any
})


export type AppProps = {
  firebaseApp: firebase.app.App
}

const App: React.FC<AppProps> = (props: AppProps) => {
  const [mainContext, setMainContext] = React.useState()
  React.useEffect(() => {
    setMainContext({
      firebaseApp: props.firebaseApp
    })
  }, [props])
  return (
    <MainContext.Provider value={mainContext}>
      <MainPage />
    </MainContext.Provider>
  );
}

export default App;
