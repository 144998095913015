import React from 'react'
import IndexNavbar from '../../components/IndexNavbar'
import GeoTargetingHeader from './geo-targeting-page/GeoTargetingHeader'
import { Container, Row, Col, Button, Modal, ModalBody, ModalFooter, InputGroup, InputGroupAddon, Input, InputGroupText, Alert } from "reactstrap";
import { Footer } from '../../components/Footer';
import Slider from 'react-slick'
import { MainContext } from '../../App';
const clients: string[] = [
    'https://storage.googleapis.com/mystoresandmore/client/FHB-White-Keys.png',
    'https://storage.googleapis.com/mystoresandmore/client/WS_logo.png',
    // 'https://storage.googleapis.com/mystoresandmore/client/aurora-white.png',
    'https://storage.googleapis.com/mystoresandmore/client/kutahight.png',
    'https://storage.googleapis.com/mystoresandmore/client/pure-winery.png',
    'https://storage.googleapis.com/mystoresandmore/client/strata-management.png',
    'https://storage.googleapis.com/mystoresandmore/client/Homecorp%2BLogo.png',
    'https://storage.googleapis.com/mystoresandmore/client/Ray-White.png'
    // ray white, home corp, 
]
type formType = {
    name?: string,
    email?: string,
    business?: string,
    phone?: string,
    country?: string
}
export type ProposalGEOTargetingProps = {

}

export function ProposalGEOTargeting(props: ProposalGEOTargetingProps) {
    let cnf: formType = {
        name: '',
        email: '',
        business: '',
        phone: '',
        country: ''
    }
    const [proposalDoanload, setProposalDownload] = React.useState(false)
    const [errInput, setErrInput] = React.useState(false)
    const [contactForm, setContactForm] = React.useState(cnf)
    const [modalOpen, setModalOpen] = React.useState(false)
    const [nameFocus, setNameFocus] = React.useState(false)
    const [emailFocus, setEmailFocus] = React.useState(false)
    const [phoneFocus, setPhoneFocus] = React.useState(false)
    const [countryFocus, setCountryFocus] = React.useState(false)
    const [businessNameFocus, setBusinessNameFocus] = React.useState(false)
    const btnClick = () => setModalOpen(!modalOpen)

    let slides: any[] = clients.map(cli => {
        return <div style={{ width: '100%', textAlign: 'center' }}>
            <div>
                <img style={{ margin: 'auto' }} src={cli} alt="..." />
            </div>

        </div>
    })
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <MainContext.Consumer>
            {
                c => {
                    if (!c) {
                        return (<h1>Tidak ada contenct</h1>)
                    }
                    const firebaseApp = c.firebaseApp;

                    let modalHeader = 'Request Proposal'
                    let isiModal = (
                        <>
                            <Alert color="warning" isOpen={errInput}>
                                <div className="container">
                                    <div className="alert-icon">
                                        <i className="now-ui-icons travel_info"></i>
                                    </div>
                                    <strong>Almost done!</strong> Please complete the form <button
                                        type="button"
                                        className="close"
                                        aria-label="Close"
                                        onClick={() => setErrInput(false)}
                                    >
                                        <span aria-hidden="true">
                                            <i className="now-ui-icons ui-1_simple-remove"></i>
                                        </span>
                                    </button>
                                </div>
                            </Alert>
                            <InputGroup
                                className={nameFocus ? "input-group-focus" : ""}
                            >
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="now-ui-icons users_circle-08"></i>
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    autoComplete="fullname"
                                    placeholder="Your Name..."
                                    type="text"
                                    onFocus={() => setNameFocus(true)}
                                    onBlur={() => setNameFocus(false)}
                                    onChange={
                                        (evt: any) => {
                                            const val = evt.target.value
                                            let frm = contactForm
                                            frm.name = val
                                            setContactForm(frm)
                                        }
                                    }
                                ></Input>
                            </InputGroup>
                            <InputGroup
                                className={businessNameFocus ? "input-group-focus" : ""}
                            >
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="now-ui-icons business_briefcase-24"></i>
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    autoComplete="fullname"
                                    placeholder="Your Business Name..."
                                    type="text"
                                    onFocus={() => setBusinessNameFocus(true)}
                                    onBlur={() => setBusinessNameFocus(false)}
                                    onChange={
                                        (evt: any) => {
                                            const val = evt.target.value
                                            let frm = contactForm
                                            frm.business = val
                                            setContactForm(frm)
                                        }
                                    }
                                ></Input>
                            </InputGroup>
                            <InputGroup
                                className={emailFocus ? "input-group-focus" : ""}
                            >
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="now-ui-icons ui-1_email-85"></i>
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    autoComplete="fullname"
                                    placeholder="Your Email..."
                                    type="text"
                                    onFocus={() => setEmailFocus(true)}
                                    onBlur={() => setEmailFocus(false)}
                                    onChange={
                                        (evt: any) => {
                                            const val = evt.target.value
                                            let frm = contactForm
                                            frm.email = val
                                            setContactForm(frm)
                                        }
                                    }
                                ></Input>
                            </InputGroup>
                            <InputGroup
                                className={phoneFocus ? "input-group-focus" : ""}
                            >
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="now-ui-icons tech_mobile"></i>
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    autoComplete="fullname"
                                    placeholder="Your Phone Number..."
                                    type="text"
                                    onFocus={() => setPhoneFocus(true)}
                                    onBlur={() => setPhoneFocus(false)}
                                    onChange={
                                        (evt: any) => {
                                            const val = evt.target.value
                                            let frm = contactForm
                                            frm.phone = val
                                            setContactForm(frm)
                                        }
                                    }
                                ></Input>
                            </InputGroup>
                            <InputGroup
                                className={countryFocus ? "input-group-focus" : ""}
                            >
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="now-ui-icons objects_globe"></i>
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    autoComplete="fullname"
                                    placeholder="Your Country..."
                                    type="text"
                                    onFocus={() => setCountryFocus(true)}
                                    onBlur={() => setCountryFocus(false)}
                                    onChange={
                                        (evt: any) => {
                                            const val = evt.target.value
                                            let frm = contactForm
                                            frm.country = val
                                            setContactForm(frm)
                                        }
                                    }
                                ></Input>
                            </InputGroup>
                        </>
                    )
                    let modalFooter = (
                        <>
                            <Button
                                color="secondary"
                                type="button"
                                className="btn-round"
                                onClick={() => setModalOpen(false)}
                            > Close </Button>
                            <Button
                                color="info"
                                type="button"
                                className="btn-round"
                                onClick={() => {
                                    //const posData = PosData(surveyData, Moment(surveyDate).format("Do MMM YYYY"), surveyTime, "[First Home Boost QUALIFY] QUALIFED TO RECEIVE AN ADDITIONAL $10,000")
                                    if (contactForm.business !== '' && contactForm.country !== '' && contactForm.email !== '' && contactForm.name !== '' && contactForm.phone !== '') {
                                        var newPostKey = firebaseApp.database().ref().child('request-proposal').push().key;
                                        firebaseApp.database().ref('request-proposal/' + newPostKey).set(contactForm).then(
                                            d => {
                                                // setModalOpen(false)
                                                setProposalDownload(true)
                                            }
                                        ).catch(
                                            e => {
                                                console.log("Masuk Error", e)
                                            }
                                        )
                                    } else {
                                        setErrInput(true)
                                    }

                                }}
                            > Request Now </Button>

                        </>
                    )
                    if (proposalDoanload) {
                        modalHeader = 'Download Proposal'
                        isiModal = (
                            <div style={{color: '#000'}}>
                            <p >
                                Click this link to <a href="https://storage.googleapis.com/mystoresandmore/proposal%20MSAM.pdf" >download proposal</a>
                            </p>
                            </div>
                        )
                        modalFooter = (
                            <Button
                                color="secondary"
                                type="button"
                                className="btn-round"
                                onClick={() => setModalOpen(false)}
                            > Close </Button>
                        )
                    }
                    return (
                        <>
                            <IndexNavbar />
                            <div className="wrapper">
                                <GeoTargetingHeader onClick={btnClick} />
                                <div className="main">
                                    <section style={{  background: 'rgb(32, 39, 62)',  padding: '50px 0' }}>
                                        <Container>
                                            <Row >
                                                <Col >
                                                    <img src="https://storage.googleapis.com/mystoresandmore/geo-targeting.jpg" alt="..." />
                                                </Col>
                                                <Col md={6} style={{ marginTop: '4%' }} >
                                                    <h4 style={{ margin: 0 }}>What is</h4>
                                                    <h3 style={{ marginBottom: 10 }}> GEO <span style={{ color: 'rgb(63, 223, 20)', fontWeight: 800 }}>Targeting Strategy</span></h3>
                                                    <ul>
                                                        <li>GEO Targeting is about finding a cluster of likeminded consumers in a centralized location that have high intent to purchase a product or service</li>
                                                        <li>We use a combination of Social Media and Retargeting Ads to attract these consumers with offers to take advantage of ‘buy now’ specials</li>
                                                        <li>We can then further identify your perfect audience of consumers taking action and find more like them</li>
                                                    </ul>
                                                    <Button onClick={btnClick} size='lg' className={"btn-round mr-1 btn btn-info"}>Request Proposal</Button>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </section>
                                    <section style={{padding: '50px 0 0 0' }}>
                                        <Container>
                                            <Row style={{
                                                backgroundImage: 'url("https://storage.googleapis.com/mystoresandmore/strategy-flow-2.png")',
                                                backgroundPosition: 'bottom',
                                                backgroundSize: 'contain',
                                                backgroundRepeat: 'no-repeat'
                                            }}>
                                                <Col md={12} style={{ marginBottom: 30 }}>
                                                    <h4 className="text-center" style={{ margin: 0 }}>Marketing</h4>
                                                    <h2 className="text-center" style={{ marginBottom: 10 }}>  <span style={{ color: 'rgb(63, 223, 20)', fontWeight: 800 }}>Process Flow</span>  </h2>
                                                </Col>
                                                <Col md={6}>
                                                    <ul>
                                                        <li>Whilst GEO Targeting is slowly becoming more well known to marketing companies we have already tried and tested the methods in a number of industries</li>
                                                        <li>We have taken this a number of steps further by seamlessly integrating our custom SMS and database strategy. This is unique to My Stores And More</li>
                                                    </ul>
                                                </Col>
                                                <Col md={6}>
                                                    <ul>
                                                        <li>Our process enables us to report and customize performance on add production, copyright, graphics, landing page performance, retargeting, video targeting, SMS offers and email offers</li>
                                                        <li>Our methodology whilst having a major impact on branding is predominately focused on generating immediate sales</li>
                                                    </ul>
                                                </Col>
                                                <Col style={{ textAlign: "center", paddingBottom: 50 }} md={12}>
                                                    <img src="https://storage.googleapis.com/mystoresandmore/flow-diagram.png" alt="" />
                                                    <Button onClick={btnClick} size='lg' className={"btn-round mr-1 btn btn-info"}>Request Proposal</Button>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </section>
                                    <section style={{
                                        padding: '50px 0',
                                        backgroundSize: 'cover', backgroundPosition: 'center',
                                        backgroundImage: 'url(https://storage.googleapis.com/mystoresandmore/client-2.jpg)'
                                    }}>
                                        <Container>
                                            <Row  >
                                                <Col md={12} style={{ marginBottom: 30 }}>
                                                    <h4 className="text-center" style={{ margin: 0 }}>Growing with us</h4>
                                                    <h2 className="text-center" style={{ marginBottom: 10 }}> <span style={{ color: 'rgb(63, 223, 20)', fontWeight: 800 }}>Those Who Entrust Us</span>  </h2>
                                                </Col>
                                                <Col style={{ textAlign: "center" }} md={12}>
                                                    <Slider {...settings}>
                                                        {slides}
                                                    </Slider>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </section>
                                    <Footer />
                                </div>
                                <Modal isOpen={modalOpen} toggle={() => setModalOpen(false)}>
                                    <div className="modal-header">
                                        <h5 className="modal-title" style={{ color: 'rgb(0, 148, 217)' }} id="exampleModalLiveLabel">
                                            {modalHeader}
                                        </h5>
                                        <button
                                            aria-label="Close"
                                            className="close"
                                            type="button"
                                            onClick={() => setModalOpen(false)}
                                        >
                                            <span aria-hidden={true}>×</span>
                                        </button>
                                    </div>
                                    <ModalBody>
                                        {isiModal}
                                    </ModalBody>
                                    <ModalFooter>
                                        {modalFooter}
                                    </ModalFooter>
                                </Modal>
                            </div>
                        </>
                    )
                }
            }
        </MainContext.Consumer>
    )
}