import React from 'react'
import IndexNavbar from '../../components/IndexNavbar'
import { Footer } from '../../components/Footer'
import { Container, Row, Col } from 'reactstrap'
export type TermsAndConditionsProps = {}
export function TermsAndConditions(props: TermsAndConditionsProps) {
    return (
        <>
            <IndexNavbar />
            <div className="wrapper">
                <div
                    className="page-header-image"
                    style={{ backgroundSize: 'cover', minHeight: 75 }}
                >

                </div>
                <section  style={{  paddingTop: 50,  }}> 
                    <Container>
                        <Row>
                            <Col>
                                <h1>Terms and Conditions</h1>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <div   className="main">
                <Container style={{background: 'rgb(32, 39, 62)', padding: 30}}>

                        <Row>
                            <Col>

                                <p><strong>1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; STANDARD TERMS AND CONDITIONS</strong>
                                    <br /> These are the standard terms and conditions for Website Design and Development and apply to all contracts and all work undertaken by My Stores and More for its clients.
        <br /> By paying your first monthly installment or project deposit, you hereby agree to all the terms and conditions listed below.
        <br /> My Stores and More will deliver a fully functional site within no less than 4-6 weeks. This is provided the client has sent all copies of required artwork, approved design, sent database extraction and responded to all required information as needed by My Stores and More. This may vary depending on the size of the project.</p>
                                <p><strong>2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; OUR FEES AND DEPOSITS<br />
                                </strong><span className="s1">No works will commence without 50% deposit payment</span>
                                    <br />
                                    <span className="s1">All codes to site will be withheld until final payment</span>
                                    <br />
                                    <span className="s1">Final payment is due on completion of project</span>
                                    <br />
                                    <span className="s1">Reoccurring payments will be charged monthly, in advance, commencing on the completion of your site build</span>
                                    <br />
                                    <span className="s1">Hourly rate for works after projection completion or out of original scope are $85 per hour &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></p>
                                <p className="p1">Refused or any failed payments will incur an additional fee of $25 administration levy.</p>
                                <p>In the event of non payment:</p>
                                <ul>
                                    <li>Your debt will be handed to a local debt collection company of which you will be responsible for our costs to collect the same</li>
                                    <li>Pursue debt collection by any and all legal means, including court orders and injunctions and the seizure of goods to the equivalent value of money owed</li>
                                </ul>
                                <p>In the event of non-payment of web hosting fees, My Stores and More reserves the right to switch off clients’ website(s). The website(s) will be restored after payment of outstanding hosting fees, plus an additional fee of $150 for restoration.</p>
                                <p><strong>3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; SUPPLY OF MATERIALS</strong>
                                    <br /> You must supply all materials and information required by us to complete the work in accordance with any agreed specification. Such materials may include, but are not limited to, photographs, written copy, logos and other printed material. Where there is any delay in supplying these materials to us, which leads to a delay in the completion of work, we have the right to extend any previously agreed deadlines by a reasonable amount.
        <br /> Where you fail to supply materials, and that prevents the progress of the work, we have the right to invoice you for any part or parts of the work already completed.
        <br /> You are responsible for ensuring that you hold the relevant copyright permissions for all material displayed on your website.
        <br /> You are responsible for proof reading and ensuring the accuracy of any content provided to us, including spelling and grammar. Any corrections required to this are deemed to be out of scope and will charged at the rate of $85 per hour.</p>
                                <p><strong>4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; VARIATIONS</strong>
                                    <br /> We are pleased to offer you the opportunity to make revisions to the design. However, we have the right to limit the number of design proposals to a reasonable amount and may charge for additional designs if you make a change to the original design specification.
        <br /> Our website development phase is flexible and allows certain variations to the original specification. However any major deviation from the specification will be charged at the rate of $85.00 per hour. If any additional variations are added to the project during any time of the construction phase, be it additional content, new 3rd party software plug-ins, images, products or any other additional material related to your website we reserve the right to charge additional hourly rates for the time taken to complete the requested or altered works.
        <br /> Any ongoing variations are generally completed within in 5 days.</p>
                                <p><strong>5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; PROJECT DELAYS AND CLIENT LIABILITY</strong>
                                    <br /> Any time frames or estimates that we give are contingent upon your full co-operation and completion and approval final content in photography for the work pages. During development there is a certain amount of feedback required in order to progress to subsequent phases. It is required that a single point of contact be appointed from your side and be made available on a daily basis in order to expedite the feedback process.</p>
                                <p><strong>6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; APPROVAL OF WORK</strong>
                                    <br /> On completion of the work you will be notified and have the opportunity to review it. You must notify us in writing of any unsatisfactory points within 5 days of such notification. Any of the work which has not been reported in writing to us as unsatisfactory within the 5 day review period will be deemed to have been approved. Once approved, or deemed approved, work cannot subsequently be rejected and the contract will be deemed to have been completed.</p>
                                <p><strong>7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; REJECTED WORK</strong>
                                    <br /> If you reject any of our work within the 5 day review period, or not approve subsequent work performed by us to remedy any points recorded as being unsatisfactory, and we, acting reasonably, consider that you have been unreasonable in any rejection of the work, we can elect to treat this contract as at an end and take measures to recover payment for the completed work.</p>
                                <p><strong>8.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; WARRANTY BY YOU AS TO OWNERSHIP OF INTELLECTUAL PROPERTY RIGHTS</strong>
                                    <br /> You must obtain all necessary permissions and authorities in respect of the use of all copy, graphic images, registered company logos, names and trademarks, or any other material that you supply to us to include in your website or web applications.</p>
                                <p>You must indemnify us and hold us harmless from any claims or legal actions related to the content of your website.</p>
                                <p><strong>9.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; LICENSING</strong>
                                    <br /> Once you have paid us in full for our work we grant to you a license to use the website and its related software and contents for the life of the website. Sales statistics and figures from your site can be used for promotion of My Stores and More</p>
                                <p><strong>10.&nbsp;&nbsp;&nbsp; SEARCH ENGINES</strong>
                                    <br /> We do not guarantee any specific position in search engine results for your website. You have the right to cancel your agreement however if Google rankings for industry specific keywords have not been achieved or maintained.</p>
                                <p><strong>11. SOCIAL MEDIA MARKETING AND GOOGLE PAY PER CLICK</strong></p>
                                <p>In addition to My Stores and More Management Fees, you will be required to pay a monthly amount to the specified platform e.g. Google, Facebook etc. This amount will be agreed with you and you agree to pay the full cost of this a month in advance. Failure to pay this on time will result in cessation of advertising until payment is made.</p>
                                <p>We will report to you once a month to keep you informed of performance and rankings for selected keywords. Any additional reporting or additional website design work will be considered to be beyond scope and subject to our standard fees.</p>
                                <p>Works including are the following:</p>
                                <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; On page website optimization</p>
                                <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Off page back linking strategy</p>
                                <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Image optimization</p>
                                <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Text and add optimization</p>
                                <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Tracking software</p>
                                <p><strong>12.&nbsp;&nbsp;&nbsp; CONSEQUENTIAL LOSS</strong>
                                    <br /> We shall not be liable for any loss or damage which you may suffer which is in any way attributable to any delay in performance or completion of our contract, however that delay arises.
        <br /> My Stores and More will not be liable or become involved in any disputes between the site holder and their clients and cannot be held responsible for any wrongdoing on the part of a site holder.
        <br /> My Stores and More will not be liable for any costs incurred, compensation or loss of earnings due to the work carried out on behalf of the client or any of the clients appointed agents.
        <br /> My Stores and More will not be liable for any costs incurred, compensation or loss of earnings due to the unavailability of the site, its servers, software or any material provided by its agents.</p>
                                <p><strong>13.&nbsp;&nbsp;&nbsp; DISCLAIMER</strong>
                                    <br /> To the full extent permitted by law, all terms, conditions, warranties, undertakings, inducements or representations whether express, implied, statutory or otherwise (other than the express provisions of these terms and conditions) relating in any way to the services we provide to you are excluded. Without limiting the above, to the extent permitted by law, any liability of My Stores and More under any term, condition, warranty or representation that by law cannot be excluded is, where permitted by law, limited at our option to the replacement, re-repair or re-supply of the services or the payment of the cost of the services that we were contracted to perform. Our terms and condtions may be updated from time to time</p>
                                <p><strong>14.&nbsp;&nbsp;&nbsp; SUBCONTRACTING</strong>
                                    <br /> We reserve the right to subcontract any services that we have agreed to perform for you as we see fit.</p>
                                <p><strong>15.&nbsp;&nbsp;&nbsp; NON-DISCLOSURE</strong>
                                    <br /> We (and any subcontractors we engage) agree that we will not at any time disclose any of your confidential information to any third party.</p>
                                <p><strong>15.&nbsp;&nbsp;&nbsp; ADDITIONAL EXPENSES</strong>
                                    <br /> You agree to reimburse us for any requested expenses which do not form part of our proposal including but not limited to the purchase of templates, third party software, stock photographs, fonts, domain name registration, web hosting or comparable expenses. The customer will be notified before purchase of any such material and approval will required by customer.</p>
                                <p><strong>16.&nbsp;&nbsp;&nbsp; BACKUPS</strong>
                                    <br /> You are responsible for maintaining your own backups with respect to your website and we will not be liable for restoring any client data or client websites except to the extent that such data loss arises out of a negligent act or omission by us.</p>
                                <p><strong>17.&nbsp;&nbsp;&nbsp; OWNERSHIP OF DOMAIN NAMES AND WEB HOSTING</strong>
                                    <br /> My Stores and More will purchase and maintain ownership of the website and all such materials, database information and domain names. Web hosting is included in the monthly fee.</p>
                                <p><strong>18.&nbsp;&nbsp;&nbsp; GOVERNING LAW</strong>
                                    <br /> The agreement constituted by these terms and conditions and any proposal will be construed according to and is governed by the laws of Australia. You and My Stores and More submit to the non-exclusive jurisdiction of the courts in and of Australia in relation to any dispute arising under these terms and conditions or in relation to any services we perform for you.</p>
                                <p><strong>19.&nbsp;&nbsp;&nbsp; CANCELLATION</strong>
                                    <br /> SEO clients are under no contract. You can cancel at any time for any reason with 45 days written notice to&nbsp;<a href="mailto:info@mystoresandmore.com">info@mystoresandmore.com</a>
                                    <br /> Client’s renting a website can cancel at any time for any reason with 45 days written notice to&nbsp;<a href="mailto:info@mystoresandmore.com">info@mystoresandmore.com</a>
                                    <br /> My Stores and More will cancel the website and any attached links, backlinks, or social media links and directory listings
        <br /> If website is cancelled due to any non-payment errors client will be notified prior to deactivating website. Re activation charge is $85 and must be paid in full before reactivation including all outstanding amounts.
        <br /> Client can moves servers at any time to service provider of their choice. Server migration fees are $85 per hour and can take up to 5 to 10 hours per site per migration depending on the size of the site of coding complexity. Server migration work MUST be ordered 14 days prior to migration time.</p>
                                <p><strong>20.&nbsp;&nbsp;&nbsp; CROSS BROWSER COMPATIBILITY</strong>
                                    <br /> By using current versions of well supported content management systems such as “WordPress”, we endeavor to ensure that the web sites we create are compatible with all current modern web browsers such as the most recent versions of Internet Explorer, Firefox, Google Chrome and Safari. Third party extensions, where used, may not have the same level of support for all browsers. Where appropriate we will substitute alternative extensions or implement other solutions, on a best effort basis, where any incompatibilities are found.</p>
                                <p><strong>21.&nbsp;&nbsp;&nbsp; E-COMMERCE</strong>
                                    <br /> You are responsible for complying with all relevant laws relating to e-commerce, and to the full extent permitted by law will hold harmless, protect, and defend and indemnify My Stores and More and its subcontractors from any claim, penalty, tax, tariff loss or damage arising from your or your clients’ use of Internet electronic commerce.</p>

                            </Col>
                        </Row>
                    </Container>


                    
                </div>
                <Footer />
            </div>
        </>
    )
}