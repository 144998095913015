import React from 'react'
import {   Row, Col, Card, CardBody, CardTitle, CardFooter } from 'reactstrap'

export type ProjectBannerStyle2Props = {
    content1Banner: string,
    content1Title: string,
    content1Link: string,
    content1Client: string,
    content1StartDate: string,
    content1Categorys: { category: string, link: string }[],
    content2Banner: string,
    content2Title: string,
    content2Link: string,
    content2Client: string,
    content2StartDate: string,
    content2Categorys: { category: string, link: string }[]
}
export function ProjectBannerStyle2(props: ProjectBannerStyle2Props) {
    return (
        <Row>
            <Col className="px-0" md="3">
                <Card
                    className="card-fashion card-background"
                    style={{
                        backgroundImage: `url("${props.content2Banner}")`
                    }}
                ></Card>
            </Col>
            <Col className="px-0" md="3">
                <Card className="card-fashion arrow-left">
                    <CardTitle tag="div">
                        <h4> <a href={`#${props.content2Link}`}> {props.content2Title} </a></h4>
                    </CardTitle>
                    <CardBody>
                        <CardFooter>
                            <div className="stats">
                                <span> <i className="now-ui-icons users_circle-08"></i> {props.content2Client} </span>
                                <span> <i className="now-ui-icons tech_watch-time"></i> {props.content2StartDate} </span>
                            </div>
                            <div className="stats-link pull-right">
                                {
                                    props.content2Categorys.map((item: any, key) => {
                                        return (
                                            <a
                                                key={key}
                                                className="footer-link"
                                                href={`#${item.link}`}
                                            > {item.category} </a>
                                        )
                                    })
                                }
                            </div>
                        </CardFooter>
                    </CardBody>
                </Card>
            </Col>
            <Col className="px-0" md="6">
                <Card
                    className="card-fashion card-background"
                    style={{
                        backgroundImage: `url("${props.content1Banner}")`
                    }}
                >
                    <CardBody>
                        <CardTitle className="text-left" tag="div">
                            <h2>
                                <a href={`#${props.content1Link}`}>{props.content1Title} </a>
                            </h2>
                        </CardTitle>
                        <CardFooter className="text-left">
                            <div className="stats">
                                <span> <i className="now-ui-icons users_circle-08"></i> {props.content1Client}</span>
                                <span> <i className="now-ui-icons tech_watch-time"></i> {props.content1StartDate} </span>
                            </div>
                            <div className="stats-link pull-right">
                                {
                                    props.content1Categorys.map((item: any, key) => {
                                        return (
                                            <a
                                                key={key}
                                                className="footer-link"
                                                href={`#${item.link}`}
                                            > {item.category} </a>
                                        )
                                    })
                                }
                            </div>
                        </CardFooter>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
} 