import React, { useState, useEffect } from 'react'
import IndexNavbar from '../../components/IndexNavbar'
import IndexHeader from '../../components/IndexHeader'
import { Footer } from '../../components/Footer'
import { ProjectBannerStyle1Props, ProjectBannerStyle1 } from './ProjectBannerStyle1'
import { ProjectBannerStyle2, ProjectBannerStyle2Props } from './ProjectBannerStyle2'
import { Container, Row, Col, Nav, NavItem, NavLink, Modal, ModalBody } from 'reactstrap'
import { PortfolioCards } from './PortfolioCards'
import { DownloadAtoZ } from '../../components/DownloadAtoZ' 
export type portfoliosCategory = "WEB DESIGN" | 'ONLINE MARKETING' | 'CONTENT WRITING' | 'CREATIVE DESIGN' | 'MUSIC & VIDEO'

export type portfolioDataType = {
    title: string,
    imageUrl: string,
    thumbUr: string,
    categories: portfoliosCategory[],
    works: string[],
    content: any;
    author: string,
    testimonial: string
}
export const portfolioData: portfolioDataType[] = [
    {
        title: 'UPWARD Studios',
        imageUrl: "/assets/images/clients/robert-up-ward-aus-img.jpg",
        thumbUr: "/assets/images/portfolio/RobertsUpwardStudios.jpg",
        categories: ['CONTENT WRITING', 'CREATIVE DESIGN', 'WEB DESIGN'],
        works: ['Web Design', "SEO"],
        author: "Robert Upward",
        content: (
            <>
                <p>Upward Studio is a boutique recording studio based in Melbourne. They compose music for a long list of high profile artists as well as music for movies and jingles for TV and radio. They needed a website that would show potential clients the high level of music production they do and a marketing program that would attract more clients.</p>
                <p>After a successful website completion, we embarked on a national SEO campaign that saw them achieve many page 1 positions in Google. Over the years the success of the SEO campaign has meant hundreds of thousands in sales as they achieved national exposure.</p>
            </>
        ),
        testimonial: "The team at My Stores and More constantly go over and beyond with their support and high level of design work. Within months of starting our SEO campaign a few years ago we started seeing a big increase in website leads. This has led to a huge increase in profitability. Our business is now heavily reliant on maintaining our Google positions but the cost is far outweighed by the returns in constant new jobs every month"
    },
    {
        title: 'Ray White ',
        imageUrl: "/assets/images/clients/ray-white.jpg",
        thumbUr: "/assets/images/portfolio/RayWhite.jpg",
        categories: ['CONTENT WRITING', 'CREATIVE DESIGN', 'ONLINE MARKETING', 'WEB DESIGN'],
        works: ['Landing Page Design', "Social Media Marketing"],
        author: " Ray White ",
        content: (
            <>
                <p>Ray White Real Estate has very specific requirements to fill rental properties in regional parts of Australia.</p>
                <p>We built a series of landing pages on their website and targeted client via social media with high end ads that stood out on Instagram and Facebook.</p>
                <p>Over a 4 months campaign we achieved the targeted number of rental customers needed to fulfill their back log of vacant homes.</p>
            </>
        ),
        testimonial: ""
    },
    {
        title: 'First Home Boost',
        imageUrl: "/assets/images/clients/first-home-boost-australia.jpg",
        thumbUr: "/assets/images/portfolio/FisrtHomeBoost.jpg",
        categories: ['WEB DESIGN', 'ONLINE MARKETING', 'CONTENT WRITING'],
        works: ['Web Design', "SEO"],
        author: "Shane Murphy",
        content: (
            <>
                <p>First Home Boost required additional performance fast as their current marketing agency was not hitting the required targets of lead generation for their first home buyers’ market.</p>
                <p>We redesigned targeting options included retargeting add production to increase click through rates and lower the cost of lead generation.</p>
                <p>We then redesigned landing pages, sales scripts, add copy, landing pages and imagery.</p>
                <p>The result has been a 600% increase in leads.</p>
            </>
        ),
        testimonial: "I have used the team at My Stores for many years and their results are always second to none. They understand the sales and marketing process of old and apply it brilliantly to the online arena. If you want result work with their team as they are yet to disappoint"
    },
    {
        title: 'Calibre Clinic ',
        imageUrl: "/assets/images/clients/calibre-clinic.jpg",
        thumbUr: "/assets/images/portfolio/CalibreClinics.jpg",
        categories: ['WEB DESIGN', 'ONLINE MARKETING'],
        works: ['Web Design', "SEO"],
        author: "Dr Jayson Oates",
        content: (
            <>
                <p>Dr Jayson Oates needed the latest in website design for his new medical procedure. Dr Jayson needed a strong online presence and high profile website to assist in getting the procedure to market quickly and a website that would work hand in hand with the national PR release that was happening with TV and Radio.</p>
                <p>We also design a strong SEO campaign that would assist in national growth.</p>
                <p>Within months we had achieved many high rankings page 1 positions in Google through high end medical based content and referring medical websites.</p>
                <p>In November 2018 we won the “Best Medical Website” for Australasia.</p>
            </>
        ),
        testimonial: "My Stores And More where crucial to our deployment and marketing campaign. Their content was first delivered at a very high standard and to the standards of the medical industry. We achieved page 1 positions within months and outranked our competitors very quickly. This resulted in many bookings for our new medical procedure. Wining the “Best Medical Website’ award was testament to their hard work and commitment"
    },
    {
        title: 'Hire Style',
        imageUrl: "/assets/images/clients/hire-style-1.jpg",
        thumbUr: "/assets/images/portfolio/HireStyle.jpg",
        categories: ['WEB DESIGN', 'CREATIVE DESIGN'],
        works: ['Web Design', "SEO"],
        author: "Janelle Green",
        content: (
            <>
                <p>Hirestyle are a prestigious event planning website and have a very diverse range of party hire equipment for weddings and special events. They needed a website that portrayed the range of party hire equipment along with exposure highlighting the events they worked with.</p>
                <p>Hirestyle also need to increase their market share through a Melbourne based Google Pay Per Click and SEO campaign.</p>
                <p>Within days we had additional leads flooding in for parties and events and soon followed strong page 1 rankings for targeted keywords.</p>
            </>
        ),
        testimonial: "Thanks Chad And Adam for your excellent work. Our website was delivered fast and leads and sales have tripled within weeks of online marketing starting"
    },
    {
        title: 'Sydney Property Repair',
        imageUrl: "/assets/images/clients/sydney-property-repair-1.jpg",
        thumbUr: "/assets/images/portfolio/SydneyPropertyRepairs.jpg",
        categories: ['WEB DESIGN', 'ONLINE MARKETING'],
        works: ['Web Design', 'SEO'],
        author: "Martin Stenning",
        content: (
            <>
                <p>Sydney Property repair need a functional website for local based trade jobs. The owner was moving from a full time job to running his own business so it was crucial the website was performing fast and producing leads as quickly as possible.</p>
                <p>A strong local based SEO campaign meant they had a good source of leads coming in quickly and the owner could concentrate on earning money and not losing focusing on understanding technology that slowed down his day to day business.</p>
            </>
        ),
        testimonial: "Thanks for getting my website up and running so quickly. Leads started coming in quickly and the business is growing each month thanks to your team. Money well invested!"
    },
    {
        title: 'Sea Vu Play Restaurant',
        imageUrl: "/assets/images/clients/Sea-Vu-Play-Bali.jpg",
        thumbUr: "/assets/images/portfolio/SeaVuBali.jpg",
        categories: ["ONLINE MARKETING", 'WEB DESIGN', 'CREATIVE DESIGN'],
        works: ['Web Design', "Content Writing", "SEO"],
        author: "Sean Lee",
        content: (
            <>
                <p>Sea Vu Play has recently undertaken renovations for expansion and needed customers fast! We undertook a full website rebuild including strong content, fresh look and feel and calendar for events.</p>
                <p>We then focused on SEO for restaurant, dining and nightlife keywords. We then included a strong social media campaign.</p>
                <p>Within a few short weeks we had increased sales and within a few months had the website ranking for all targeted keywords to ensure a constant influx of new and ongoing customers.</p>
            </>
        ),
        testimonial: "Thanks to Chad and his team for helping to increase sales. Your team are fast, proficient and easy to work with. Your knowledge of online marketing is 2nd to none"
    },
    {
        title: 'AMO Spa and Salon',
        imageUrl: "/assets/images/clients/amo-spa-bali-1.jpg",
        thumbUr: "/assets/images/portfolio/AmoSpaBali.jpg",
        categories: ['WEB DESIGN', 'ONLINE MARKETING', 'CONTENT WRITING'],
        works: ['Web Design', "SEO", "Content Writing"],
        author: "Navia Nguyen",
        content: (
            <>
                <p>AMO Spa and Salon was in the midst of a strength growth phase when their friend told them to work with My Stores And More. The owners need dynamic pricing and online booking system that we coded to work for end users allowing them to book via multiple locations.</p>
                <p>We then embarked on an SEO campaign that within months saw an increase in customers by over 200 new clients each month!</p>
                <p>We also added a Social media campaign to ensure we were attracting new clients from not only Google but Facebook and Instagram also.</p>
            </>
        ),
        testimonial: "We couldn’t be happier with the work done by Chad and the team at My Stores. We had huge increase in customer almost instantly across all locations. The costs of building the new website was paid for within weeks of all the new customers"
    },
    {
        title: 'Academy Face and Body',
        imageUrl: "/assets/images/clients/academy-face-and-body.jpg",
        thumbUr: "/assets/images/portfolio/AcademyFaceAndBody.jpg",
        categories: ['ONLINE MARKETING'],
        works: ["SEO", "Social Media Marketing"],
        author: "Zonia Wulff",
        content: (
            <>
                <p>Academy Face And Body not only needed a new look to ensure there online reputation was as strong as the cosmetic team that worked for them but also to re gain their presence in the local market as competitors has been investing strongly on their online brands</p>
                <p>They had also had a number of different SEO teams work on their project over the years with little or no results.</p>
                <p>We rebuilt their content with a very strong focus on high end revering medical websites in Australia and globally.</p>
                <p>Within months we had over 30 targeted keywords on page 1 of Google delivery more leads and sales</p>
            </>
        ),
        testimonial: "Finally, a company that deliver on SEO. Such a great team to work with. Always available and delivered result fast. If you need SEO then you must work with Chad and My Stores And More."
    },
    {
        title: 'Academy Ladies Clinic',
        imageUrl: "/assets/images/clients/academy-ladies-clinic.jpg",
        thumbUr: "/assets/images/portfolio/AcademyLadiesClinics.jpg",
        categories: ['WEB DESIGN', 'ONLINE MARKETING'],
        works: ['Web Design', "SEO"],
        author: "Rozlyn Swan",
        content: (
            <>
                <p>Academy Laser Clinics are Sydney’s leading laser clinic. They needed a full website rebrand and a strong PPC and SEO campaign.</p>
                <p>We worked with the team to find highly target keywords with the right bidding strategy to reduce Google monthly costs.</p>
                <p>Within 6 months we had targeted keywords ranking on Page 1 of Google and a high-performance website that delivered leads into sales month after month.</p>
            </>
        ),
        testimonial: "I love working with a professional team that understand my exact needs and demands. You outperformed expectations each and every month with increased sales and high demands from our internal team"
    },
    {
        title: 'Kuta Heights',
        imageUrl: "/assets/images/clients/kuta-height-hi-res.jpg",
        thumbUr: "/assets/images/portfolio/KutaHeights.jpg",
        works: ['Web Design', "SEO", "Facebook Marketing"],
        categories: [],
        author: "Rosalie Dobson",
        content: (
            <>
                <p>Kuta Heights Development have over 200 prime beach view land parcels for sale. However, they started their website on a DIY platform and after months of zero traction realized they were nowhere to be seen in Google.</p>
                <p>We designed the latest in lead capturing Google ads and a strong SEO campaign with high quality real estate and news backlinks through press release to gain momentum and visibility as quickly as possible.</p>
                <p>The above marketing with a beautifully designed website ensured leads coming through within the first week of launch.</p>
            </>
        ),
        testimonial: "Chad and the team worked fast to rebuild our website and marketing campaign. We have seen a 500% increase in leads already. They were easy to work with and no what they are talking about when it comes to online marketing. Their proof is in the increases leads almost immediately."
    },
    {
        title: 'Bali Hotels and More',
        imageUrl: "/assets/images/clients/bali-hotels-and-more.jpg",
        thumbUr: "/assets/images/portfolio/BaliHotelsAndMore.jpg",
        works: ['Web Design', "SEO", 'Content Writing', 'Video Creating'],
        categories: ['CONTENT WRITING', 'CREATIVE DESIGN', 'MUSIC & VIDEO', 'ONLINE MARKETING', 'WEB DESIGN'],
        author: "",
        content: (
            <>
                <p>Bali Hotels And More is a customized website coded by our inhouse team of high end engineers. Bali Hotels have over 2000 hotels online with instant and dynamic pricing and online booking.</p>
                <p>The website took 6 months to build and since launch has achieved over 1 million dollars in online and offline sales.</p>
                <p>With a global marketing campaign consisting of SEO, Google PPC and Social media</p>
                <p>The website competes with such global brands as Booking.com and Airbnb.</p>
            </>
        ),
        testimonial: ""
    },
    {
        title: 'Loud Crowd Music',
        imageUrl: "/assets/images/clients/robert-up-ward-aus-img.jpg",
        thumbUr: "/assets/images/portfolio/LoudCrowd.jpg",
        works: ['Web Design', "SEO"],
        categories: ['WEB DESIGN', 'CREATIVE DESIGN', 'ONLINE MARKETING'],
        author: "Angie Coffey",
        content: (
            <>
                <p></p>Loud Crowd Music creates jingles and custom music for Australia’s leading commercial brands. We built a high-end website that would attract the best advertising agencies and Australia top retail brands.
                <p></p>We also selected top keywords that the industry uses to find companies like Loud Crowd Music. Within a few short months we had the website ranking high in Google.
            </>
        ),
        testimonial: "We had a very specific niche that My Stores And More were able to understand very quickly. The website was a beautiful design and clients have been coming to our website within months of starting SEO."
    },
    {
        title: 'Bali Tours and More',
        imageUrl: "/assets/images/clients/bali-tours-and-more.jpg",
        thumbUr: "/assets/images/portfolio/BaliToursAndMore.jpg",
        works: ['Web Design', "SEO", 'Content Writing', 'Video Creating'],
        categories: ['CONTENT WRITING', 'CREATIVE DESIGN', 'MUSIC & VIDEO', 'ONLINE MARKETING', 'WEB DESIGN'],
        author: "",
        content: (
            <>
                <p>As a global tour provider for Bali, Bali Tours And More needed a website with attractive design and online booking system that worked in multiple currencies.</p>
                <p>They also needed a social media, Google PPC and Google SEO campaign that allowed top page 1 results on a global basis.</p>
                <p>With over 30,000 words of content we had delivered their requirements within 2 short months.</p>
                <p>Bali Tours And More now rank globally and enjoy daily online bookings from their website achieving strong ROI from online marketing.</p>
            </>
        ),
        testimonial: ""
    },
    {
        title: 'Bali Villas and More',
        imageUrl: "/assets/images/clients/bali-villas-and-more.jpg",
        thumbUr: "/assets/images/portfolio/BaliVillasAndMore.jpg",
        works: ['Web Design', "SEO", 'Content Writing', 'Video Creating'],
        categories: ['CONTENT WRITING', 'CREATIVE DESIGN', 'MUSIC & VIDEO', 'ONLINE MARKETING', 'WEB DESIGN'],
        author: "",
        content: (
            <>
                <p>Bali Villas And More have more than 1000 villas listed on their website. They have built this over the last 2 years with the direct involvement of My Stores And More as we have worked with them to match their growth.</p>
                <p>We have been instrumental in developing marketing campaign in social media, Google PPC and SEO on a global level.</p>
                <p>We assist in database management and website design and growth and help them understand where customers are coming from and how to increases sales on a monthly basis.</p>
            </>
        ),
        testimonial: ""
    }
]


export type PortfolioProps = {}
export function Portfolio(props: PortfolioProps) {
    // let cat: portfoliosCategory = "ONLINE MARKETING"
    const [portfolioCategory, setPortfolioCategory] = useState()
    const [showModal, setShowModal] = useState()

    React.useEffect(() => {
        document.body.classList.add("blog-posts");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        return function cleanup() {
            document.body.classList.remove("blog-posts");
            document.body.classList.remove("sidebar-collapse");
        };
    });
    let reletedPortfolio1: ProjectBannerStyle1Props = {
        content1Banner: "/assets/images/clients/pure-wynery.jpg",
        content1Title: "GEO Targeting for consumer sales",
        content1Link: "/portfolio/pure-winery",
        content1Client: "Pure Winery",
        content1StartDate: "Oct 19, 2019",
        content1Categorys: [
            { category: "Online Marketing", link: "/portfolio/online-marketing" }
        ],
        content2Banner: "/assets/images/clients/first-home-buyer.jpg",
        content2Title: "First Home Buyer Australian Marketing",
        content2Link: "/portfolio/first-home-buyer",
        content2Client: "First Home Boost",
        content2StartDate: "Sept 26, 2019",
        content2Categorys: [
            { category: "Online Marketing", link: "/portfolio/online-marketing" },
            { category: "Web Design", link: "/portfolio/web-design" }
        ]
    }
    let reletedPortfolio2: ProjectBannerStyle2Props = {
        content1Banner: "/assets/images/clients/homepage-image1.jpg",
        content1Title: "The Money Mentor",
        content1Link: "/portfolio/greame-holm",
        content1Client: "Greame Holm",
        content1StartDate: "Sept 12, 2019",
        content1Categorys: [
            { category: "Content Writing", link: "/portfolio/content-writing" },
            { category: "Web Design", link: "/portfolio/web-design" },
        ],
        content2Banner: "/assets/images/clients/martika-music.jpg",
        content2Title: "Martika Music",
        content2Link: "/portfolio/martica-music",
        content2Client: "Martika",
        content2StartDate: "Oct 19, 2019",
        content2Categorys: [
            { category: "Music", link: "/portfolio/music-video" },
            { category: "Web Design", link: "/portfolio/web-design" },
        ]
    }

    let portFoliosData: portfolioDataType[] = []
    let portfolioIndex: { [title: string]: portfolioDataType } = {}
    for (let p of portfolioData) {
        portfolioIndex[p.title] = p
        if (p.categories.indexOf(portfolioCategory) > -1) {
            portFoliosData.push(p)
        }
    }

    const modalDiClick = (title: string) => setShowModal(title)
    useEffect(
        () => {
            setPortfolioCategory("ONLINE MARKETING")
        }, []
    )

    // console.log("portFoliosData", portFoliosData, "portfolioCategory", portfolioCategory)

    let isiModal: portfolioDataType = portfolioIndex[showModal]
    console.log("isiModal", isiModal, portfolioIndex)
    return (
        <>
            <IndexNavbar />
            <div className="wrapper">
                <IndexHeader />
                <div className="projects-4">
                    <Container fluid>
                        <ProjectBannerStyle1 {...reletedPortfolio1} />
                        <ProjectBannerStyle2 {...reletedPortfolio2} />
                    </Container>

                </div>
                <div className="main">
                    <Container>
                        <Row>
                            <Col md={12}>
                                <h3 style={{ textAlign: 'center' }}>You may also be interested in</h3>
                            </Col>
                            <Col md={12}>
                                <Nav
                                    className="nav-pills-primary nav-pills-just-icons justify-content-center portfolio"
                                    pills
                                    role="tablist"
                                >
                                    <NavItem>
                                        <NavLink
                                            className={portfolioCategory === "ONLINE MARKETING" ? "active" : ""}
                                            href="#pablo"
                                            onClick={e => {
                                                e.preventDefault();
                                                setPortfolioCategory("ONLINE MARKETING");
                                            }}
                                        >
                                            <img src="/assets/images/online-marketing.png" alt="" />
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={portfolioCategory === "WEB DESIGN" ? "active" : ""}
                                            href="#pablo"
                                            onClick={e => {
                                                e.preventDefault();
                                                setPortfolioCategory("WEB DESIGN");
                                            }}
                                        >
                                            <img src="/assets/images/web-design.png" alt="" />
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={portfolioCategory === "CONTENT WRITING" ? "active" : ""}
                                            href="#pablo"
                                            onClick={e => {
                                                e.preventDefault();
                                                setPortfolioCategory("CONTENT WRITING");
                                            }}
                                        >
                                            <img src="/assets/images/content-writing.png" alt="" />
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={portfolioCategory === "CREATIVE DESIGN" ? "active" : ""}
                                            href="#pablo"
                                            onClick={e => {
                                                e.preventDefault();
                                                setPortfolioCategory("CREATIVE DESIGN");
                                            }}
                                        >
                                            <img src="/assets/images/createve-design.png" alt="" />
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={portfolioCategory === "MUSIC & VIDEO" ? "active" : ""}
                                            href="#pablo"
                                            onClick={e => {
                                                e.preventDefault();
                                                setPortfolioCategory("MUSIC & VIDEO");
                                            }}
                                        >
                                            <img src="/assets/images/audio-video.png" alt="" />
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <PortfolioCards portFoliosData={portFoliosData} category={portfolioCategory} onClick={modalDiClick} />
                            </Col>
                        </Row>
                    </Container>
                </div>
                <DownloadAtoZ style={ { background: "rgb(30, 39, 61)" }}/> 
                <Footer />
            </div>
            <Modal size="lg" isOpen={showModal ? true : false} toggle={() => setShowModal(null)}>
                <div className="modal-header">
                    <h5 style={{ color: "#0f0f0f" }} className="modal-title" id="exampleModalLiveLabel"> {isiModal && isiModal.title ? isiModal.title : ''} </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        type="button"
                        onClick={() => setShowModal(null)}
                    >
                        <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i></span>
                    </button>
                </div>
                <ModalBody>
                    <img width="100%" src={`${isiModal && isiModal.imageUrl ? isiModal.imageUrl : ''}`} alt="" />
                    <p style={{ color: "#0f0f0f" }}>
                        {isiModal && isiModal.content ? isiModal.content : ''}
                    </p>
                    <div style={{ color: "#0f0f0f", display: isiModal && isiModal.testimonial && isiModal.testimonial.length > 5 ? "" : "none", border: '1px solid #f0f0f0', borderRadius: 10, padding: 10, background: '#f0f0f0' }}>
                        <p style={{ fontStyle: 'italic' }}> {isiModal && isiModal.testimonial}  </p>
                        <p><i className="now-ui-icons users_circle-08"></i> {isiModal && isiModal.author}</p>

                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}