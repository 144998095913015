import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { Link } from 'react-router-dom';
import { SocialMedia } from './SocialMedia';
export type FooterProps = {
    type?: "HOMEPAGE" | "SIMPLE"
}
export function Footer(props: FooterProps) {
    let d = new Date();
    let year = d.getFullYear();
    return (
        <footer className="footer footer-big" style={{ padding: 0 }}>
            <section style={{ padding: "30px 0", borderBottom: '1px solid rgb(32, 39, 62)', display: props.type === "HOMEPAGE" ? "" : 'none' }} >
                <Container>
                    <Row>
                        <Col md="4" style={{ textAlign: 'center' }}>
                            <div className="info info-hover" style={{ padding: '30px 0' }}>
                                <div className="icon">  <i style={{ fontSize: 30 }} className="now-ui-icons tech_headphones"></i></div>
                                <h4 className="info-title" ><a href="tel:1300520212">1300 520 212</a></h4>
                            </div>
                        </Col>
                        <Col md="4" style={{ textAlign: 'center' }}>
                            <div className="info info-hover" style={{ padding: '30px 0' }}>
                                <div className="icon"> <i style={{ fontSize: 30 }} className="now-ui-icons location_pin"></i> </div>
                                <h4 style={{ color: 'rgb(162, 162, 162)' }} className="info-title" >14/380 St Kilda Rd,<br />
                                    Melbourne VIC 3004, Australia</h4>
                            </div>
                        </Col>
                        <Col md="4" style={{ textAlign: 'center' }} >
                            <div className="info info-hover" style={{ padding: '30px 0' }}>
                                <div className="icon"><i style={{ fontSize: 30 }} className="now-ui-icons ui-1_email-85"></i></div>
                                <h4 className="info-title" ><a href="mailto:info@mystoresandmore.com" >info@mystoresandmore.com</a></h4>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section style={{ padding: '50px 0', textAlign: "left", display: props.type === "SIMPLE" ? "none" : "" }}>
                <Container>
                    <Row>
                        <Col md={3}>
                            <img src="/assets/images/logo.png" alt="" />
                            <SocialMedia />
                        </Col>
                        <Col md={3}>
                            <h5>Services</h5>
                            <ul className="links-vertical">
                                <li><Link to="/services/online-marketing">Online Marketing</Link></li>
                                <li><Link to="/services/creative-design">Creative Design</Link></li>
                                <li><Link to="/services/content-writing">Content Writing</Link></li>
                                <li><Link to="/services/web-design">Web Design</Link></li>
                                <li><Link to="/services/music-video">Music & Wideo</Link></li>
                            </ul>
                        </Col>
                        <Col md={3}>
                            <h5>Help</h5>
                            <ul className="links-vertical">
                                <li><Link to="/faqs">FAQ’s</Link></li>
                                <li><Link to="/terms-conditions">Term & Condition</Link></li>
                                <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                <li><Link to="/contact-us">Contact Us</Link></li>
                                <li><Link to="/sitemap">Sitemap</Link></li>
                            </ul>
                        </Col>
                        <Col md={3}>
                            <div
                                className="fb-page"
                                data-href="https://www.facebook.com/mystoresandmore/"
                                data-tabs="timeline"
                                data-width=""
                                data-height="200"
                                data-small-header="true"
                                data-adapt-container-width="true" data-hide-cover="true" data-show-facepile="true">
                                <blockquote cite="https://www.facebook.com/mystoresandmore/" className="fb-xfbml-parse-ignore">
                                    <a href="https://www.facebook.com/mystoresandmore/">My Stores and More</a>
                                </blockquote>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section style={{ background: '#1e273d', padding: "15px" }}>
                <Container>
                    <Row>
                        <Col md={6} style={{ textAlign: "left" }}>
                            &copy;{year} My Stores and More
                    </Col>
                        <Col md={6}>
                            <p style={{ display: props.type === "SIMPLE" ? "" : 'none', margin: 0 }} >
                                <Link to="/faqs">FAQ’s</Link> |  <Link to="/terms-conditions">Term & Condition</Link> |  <Link to="/privacy-policy">Privacy Policy</Link> | <Link to="/contact-us">Contact Us</Link> | <Link to="/sitemap">Sitemap</Link>
                            </p>
                        </Col>
                    </Row>
                </Container>


            </section>
        </footer>
    )
}