import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Page404 } from '../page404/Page404'
import { BaliWebDesign } from './bali-web-design/BaliWebDesign'
import { AustralianSeoServices } from './australian-seo-services/AustralianSeoServices'
export type StaticMainContentProps = {}
export function StaticMainContent(props: StaticMainContentProps) {
    let {slug} = useParams()
    let history = useHistory()
    if(slug === "bali-web-design"){
        return <BaliWebDesign />
    } if (slug === 'australian-seo-services') {
        return <AustralianSeoServices />
    }

    history.push("404") 
    return <Page404 />
}