/*eslint-disable*/
import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import Slider from "react-slick";

type GeoTargetingHeaderProps = {
    onClick: () => any
}

function GeoTargetingHeader(props: GeoTargetingHeaderProps ) {
  let pageHeader = React.createRef<HTMLDivElement>()
  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current!.style.transform = "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    //autoplay: true,
  };

  return (
    <>
      <div className="page-header clear-filter" filter-color="black" >
        <div
          className="page-header-image"
          style={{ backgroundImage: "url(https://storage.googleapis.com/mystoresandmore/website/banner-online-marketing.jpg)", backgroundSize: 'cover' }}
          ref={pageHeader}
        />
        <Container>
          <Slider {...settings}>
            <div>
              <Row>
                <Col md={6}>
                  <img src="https://storage.googleapis.com/mystoresandmore/website/geo-targeting.png" style={{ float: 'left' }} alt={"wawan"} />
                </Col>
                <Col md={6} style={{ textAlign: 'left', marginTop: '10%' }} >
                  <h2 style={{ color: "#3fdf14", textTransform: "uppercase", fontWeight: 800, fontSize: 36, margin: 0 }}>GEO Targeting </h2>
                  <h4 style={{margin: 0, color: '#0094d9', fontSize: 24, fontWeight: 800}}>Social Media & Re-targeting Ads </h4>
                  <ul>
                    <li>We are able to include and exclude locations based on GEO clusters or location groups</li>
                    <li>We can measure results for each area and quickly identify patterns of upper performance or lower performance of sales or lead generation</li>
                    <li>This method can boost store locations sales and attract clients from other stores to your store at the perfect time when they have intent to purchase furniture</li>
                  </ul>
                  <Button onClick={props.onClick} size='lg' className={"btn-round mr-1 btn btn-info"}>Request Proposal</Button>
                </Col>
              </Row>
            </div>
             
          </Slider>
        </Container>
      </div>
    </>
  );
}

export default GeoTargetingHeader;
