import React from "react";
// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  Nav,
  Container,
  UncontrolledTooltip,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";

function IndexNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [logoHight, setLogoHight] = React.useState(60);

  React.useEffect(() => {

    const updateNavbarColor = () => {

      if (
        document.documentElement.scrollTop > 149 ||
        document.body.scrollTop > 149
      ) {
        setNavbarColor("");
        setLogoHight(35);
      } else if (
        document.documentElement.scrollTop < 150 ||
        document.body.scrollTop < 150
      ) {
        setNavbarColor("navbar-transparent");
        setLogoHight(60);


      }
    };



    window.addEventListener("scroll", updateNavbarColor);


    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}

      <Navbar className={"fixed-top bg-white " + navbarColor} expand="lg" color="info"  >
        <Container>
          <div className="navbar-translate">
            <NavbarBrand href="/" id="navbar-brand" >
              <img src="/assets/images/logo.png" alt="" height={`${logoHight}px`} />
            </NavbarBrand>
            <UncontrolledTooltip target="#navbar-brand">
              Bali's Best Tour Agent
            </UncontrolledTooltip>
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
              <NavItem>
                <NavLink
                  href="/" 
                >
                  <p>Home</p>
                </NavLink>
              </NavItem>
            
            <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  data-toggle="dropdown"
                  href="#pablo"
                  id="navbarDropdownMenuLink1"
                  nav
                  onClick={e => e.preventDefault()}
                >
                   <p>Services</p>
                </DropdownToggle>
                <DropdownMenu aria-labelledby="navbarDropdownMenuLink1" right>
                  <DropdownItem tag={Link} to="/services/online-marketing/" >  
                    ONLINE MARKETING
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/services/geo-targeting-strategy" >  
                    GEO TARGETING
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/services/creative-design/" > 
                    CREATIVE DESIGN
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/services/content-writing/" > 
                    CONTENT WRITING
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/services/web-design/" > 
                    WEB DESIGN
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/services/music-video/" > 
                    MUSIC & VIDEO
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>
                <NavLink
                  href="/portfolio" 
                >
                  <p>Portfolio</p>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="/our-team"
                   
                >
                  <p>Our Team</p>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="/contact-us" 
                >
                  <p>Contact Us</p>
                </NavLink>
              </NavItem>
              </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default IndexNavbar;
