import React from 'react'
import { Container, Row, Col } from 'reactstrap'
export type TemplateClientContentProps = {
    imageUrl: string,
    imagePosition: "LEFT" | 'RIGHT' | 'CENTER',
    imageAltText: string,
    style: React.CSSProperties,
    children?: any,
    textPaddingTop: number
}
export function TemplateClientContent(props: TemplateClientContentProps) {

    let md = 6
    if (props.imagePosition === 'CENTER') {
        md= 12
    }
    let left = <Col md={md}> <img src={props.imageUrl} alt={props.imageAltText} /> </Col>
    let right = <Col style={{ paddingTop: props.textPaddingTop }} md={md}>  {props.children} </Col>
    if (props.imagePosition === 'RIGHT') {
        let tmpLeft = left;
        left = right
        right = tmpLeft
    } 
    return (
        <>
            <section style={props.style}>
                <Container>
                    <Row>
                        {left}
                         {right}
                    </Row>
                </Container>
            </section>
        </>
    )
}