import React from 'react'
import IndexNavbar from '../../components/IndexNavbar' 
import { Footer } from '../../components/Footer'
import { Container, Row, Col, Card, CardBody, CardTitle, CardFooter, Button } from 'reactstrap'
import { DownloadAtoZ } from '../../components/DownloadAtoZ'
import { Testimonials } from '../../components/Testimonials'
import { useHistory } from 'react-router-dom'
import  OurTeamHeader  from './OurTeamHeader'
export type OurTeamProps = {}

const teams: { [nama: string]: TeamDisplayPropsType } = {
    chad: {
        name: 'Chad W. Egan',
        position: 'Business Development Manager',
        pic: '/assets/images/team/chad.jpg',
        content: "Website Development, Digital Marketing, SEO, Social Media",
        socialLink: {
            fb: "",
            in: '',
            tw: ''
        }
    },
    putu: {
        name: 'I Putu Arka Suryawan',
        position: 'Senior Web Developer',
        pic: '/assets/images/team/putu.jpg',
        content: "Website Development & Design, Coding & Software Specialist",
        socialLink: {
            fb: "",
            in: '',
            tw: ''
        }
    },
    trezy: {
        name: 'Putra Triadi',
        position: 'Senior Graphic Designer',
        pic: '/assets/images/team/trezy.jpg',
        content: "Graphic Design, Website Development & Design, Social Media",
        socialLink: {
            fb: "",
            in: '',
            tw: ''
        }
    },
    elissa: {
        name: 'Elissa Doyle ',
        position: 'Senior Content Writer',
        pic: '/assets/images/team/elissa.jpg',
        content: "Content writing & Editing, PR & Marketing, Social Media",
        socialLink: {
            fb: "",
            in: '',
            tw: ''
        }
    },
    hafiz: {
        name: 'Hafiz Safero',
        position: 'Social Media Moderator',
        pic: '/assets/images/team/hafiz.jpg',
        content: "Social Media, Database Management, Market Research",
        socialLink: {
            fb: "",
            in: '',
            tw: ''
        }
    },
    craig: {
        name: 'Craig FitzSimmons',
        position: 'International Sales',
        pic: '/assets/images/team/craig.jpg',
        content: "Sales & Marketing, Outreach, Market Research",
        socialLink: {
            fb: "",
            in: '',
            tw: ''
        }
    },
}

type TeamDisplayPropsType = {
    name: string,
    position: string,
    pic: string,
    content: string,
    socialLink: { [code: string]: string }
}

const TeamDisplay: React.FC<any> = (props: TeamDisplayPropsType) => {

    return (
        <Col md={4}>
            <Card style={{background: '#454c65'}} className="card-profile">
                <div className="card-avatar">
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                        <img
                            alt="..."
                            className="img img-raised"
                            src={props.pic}
                        ></img>
                    </a>
                </div>
                <CardBody>
                    <CardTitle style={{ color: 'rgb(63, 223, 20)' }} tag="h3">{props.name}</CardTitle>
                    <h6 className="category text-info">{props.position}</h6>
                    <p className="card-description" style={{color: '#f0f0f0'}}>
                        {props.content}
                    </p>
                    <CardFooter>
                    </CardFooter>
                </CardBody>
            </Card>
        </Col>
    )
}

export function OurTeam(props: OurTeamProps) {
    let history = useHistory()
    //
    return (
        <>
        
            <IndexNavbar />
            <div className="wrapper">
                <OurTeamHeader />
                <div className="main" >
                    <section style={{ background: '#1f273e', paddingTop: 50 }}>
                        <Container>
                            <Row>
                                <TeamDisplay {...teams['chad']} />
                                <Col>
                                    <Card style={{background: '#2c3654'}} >
                                        <CardBody  className="content-danger">
                                            <h6 className="category-social"><i className="fas fa-users"></i> Executive Team </h6>
                                            <CardTitle tag="h4">
                                                <a href="#pablo" style={{color: 'rgb(63, 223, 20)'}} onClick={e => e.preventDefault()}>
                                                    My Stores and More Team
                      </a>
                                            </CardTitle>
                                            <p className="card-description">
                                            Our international team are digital and marketing specialists, skilled in  building high-performing websites tailored to highest possible ROI
                    </p>
                                            <CardFooter className="text-center">
                                                <Button
                                                    className="btn-round"
                                                    color="info"
                                                    href="#pablo"
                                                    onClick={e => history.push('/contact-us')}
                                                >
                                                    Contact Us
                      </Button>
                                            </CardFooter>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <TeamDisplay {...teams['putu']} /> 
                                <TeamDisplay {...teams['trezy']} />
                                <TeamDisplay {...teams['elissa']} />
                                <TeamDisplay {...teams['hafiz']} />
                                <TeamDisplay {...teams['craig']} />
                            </Row>

                        </Container>
                    </section>
                    <DownloadAtoZ />
                    <Testimonials />
                    <Footer />
                </div>
            </div>
        </>
    )
}