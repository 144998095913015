/*eslint-disable*/
import React from "react";
import { Container, Row, Col, } from "reactstrap";
import Slider from "react-slick";

function ContentWritingHeader() {
  let pageHeader = React.createRef<HTMLDivElement>()
  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current!.style.transform = "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    //autoplay: true,
  };

  return (
    <>
      <div className="page-header clear-filter" filter-color="black" >
        <div
          className="page-header-image"
          style={{ backgroundImage: "url('https://storage.googleapis.com/mystoresandmore/website/banner-online-marketing.jpg')", backgroundSize: 'cover' }}
          ref={pageHeader}
        />
        <Container>
          <Slider {...settings}>
            <div>
              <Row>
                <Col md={6}>
                  <img src="https://storage.googleapis.com/mystoresandmore/website/content-writing.png" style={{ float: 'left' }} alt={"wawan"} />
                </Col>
                <Col md={6} style={{ textAlign: 'left', marginTop: '15%' }} >
                  <h2 style={{ color: "#3fdf14", textTransform: "uppercase", fontWeight: 800, fontSize: 36, margin: 0 }}>Content Writing </h2>
                  <h4>Professional content writing ensures the ultimate voice for your business, builds trust, increases engagement and encourages conversions</h4>
                  <ul>
                    <li>Ensure that your content speaks in a language that connects to both your new and existing customers</li>
                    <li>Enhance your brand with professionally written content in a consistent tone of voice</li>
                    <li>Build trust, loyalty and return visits to your website</li>
                  </ul>
                </Col>
              </Row>
            </div>
             
          </Slider>
        </Container>
      </div>
    </>
  );
}

export default ContentWritingHeader;
