import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { HomePage } from './home-page/HomePage';
import { ContactUs } from './contact-us/ContactUs';
import { OurTeam } from './our-team/OurTeam';
import { Portfolio } from './portfolio/Portfolio';
import { Services } from './services/Services';
import { FAQs } from './faqs/FAQs';
import { TermsAndConditions } from './term-and-condition/TermsAndConditions'
import { PrivacyPolicy } from './privacy-policy/PrivacyPolicy';
import { SiteMap } from './sitemap/SiteMap'
import { Page404 } from './page404/Page404';
import { OnlineMarkering } from './services/online-marketing/OnlineMarkering';
import { CreativeDesign } from './services/creative-design/CreativeDesign';
import { ContentWriting } from './services/content-writing/ContentWriting';
import { WebDesign } from './services/web-design/WebDesign';
import { MusicAndVideo } from './services/music-and-video/MusicAndVideo';
import { StaticMainContent } from './static-content/StaticMainContent'; 
import { ProposalGEOTargeting } from './proposal/ProposalGEOTargeting';

export type MainPageProps = {}
export function MainPage(props: MainPageProps) {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/" render={props => <HomePage />} />
                <Route exact path="/contact-us" render={props => <ContactUs />} />
                <Route exact path="/our-team" render={props => <OurTeam />} />
                <Route exact path="/portfolio" render={props => <Portfolio />} />
                <Route exact path="/services" render={props => <Services />} />
                <Route exact path="/services/online-marketing" render={props => <OnlineMarkering />} />
                <Route exact path="/services/creative-design" render={props => <CreativeDesign />} />
                <Route exact path="/services/content-writing" render={props => <ContentWriting />} />
                <Route exact path="/services/web-design" render={props => <WebDesign />} />
                <Route exact path="/services/music-video" render={props => <MusicAndVideo />} />
                <Route exact path="/faqs" render={props => <FAQs />} />
                <Route exact path="/terms-conditions" render={props => <TermsAndConditions />} />
                <Route exact path="/privacy-policy" render={props => <PrivacyPolicy />} />
                <Route exact path="/sitemap" render={props => <SiteMap />} /> 
                <Route exact path="/services/geo-targeting-strategy" render={props => <ProposalGEOTargeting />} /> 
                <Route exact path="/404" render={props => <Page404 />} />
                <Route exact path="/:slug" render={props => <StaticMainContent />} />
                <Redirect to="/404" />
            </Switch>
        </BrowserRouter>
    )
}