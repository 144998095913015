/*eslint-disable*/
import React from "react";
import { Container, Row, Col, } from "reactstrap";
import Slider from "react-slick";

function OnlineMarkeringHeader() {
  let pageHeader = React.createRef<HTMLDivElement>()
  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current!.style.transform = "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    //autoplay: true,
  };

  return (
    <>
      <div className="page-header clear-filter" filter-color="black" >
        <div
          className="page-header-image"
          style={{ backgroundImage: "url('https://storage.googleapis.com/mystoresandmore/website/banner-online-marketing.jpg')", backgroundSize: 'cover' }}
          ref={pageHeader}
        />
        <Container>
          <Slider {...settings}>
            <div>
              <Row>
                <Col md={6}>
                  <img src="https://storage.googleapis.com/mystoresandmore/website/digital-marketing.png" style={{ float: 'left' }} alt={"wawan"} />
                </Col>
                <Col md={6} style={{ textAlign: 'left', marginTop: '15%' }} >
                  <h2 style={{ color: "#3fdf14", textTransform: "uppercase", fontWeight: 800, fontSize: 36, margin: 0 }}>Online Marketing</h2>
                  <h4>Our specialized marketing teams work to increase your conversions, repeat traffic, and expand your online visibility.</h4>
                  <ul>
                    <li>Increase your traffic with search engine optimization and pay-per-click advertising</li>
                    <li>Refresh your brand image with credible online reputation management</li>
                    <li>Get qualified leads and turn them into brand ambassadors</li>
                  </ul>
                </Col>
              </Row>
            </div> 
          </Slider>
        </Container>
      </div>
    </>
  );
}

export default OnlineMarkeringHeader;
