import React from 'react'
import { Row, Col, Card, CardTitle, CardBody, CardFooter } from 'reactstrap'
export type GrowBusninessProps = {}
export function GrowBusniness(props: GrowBusninessProps) {
    return (
        <section className='grouw-business' style={{ background: 'rgb(32, 39, 62)' }}>
            <Row>
                <Col className="px-0" md="6" sm={6} lg={3}>
                    <Card className="card-fashion" style={{ marginBottom: 0, background: 'rgb(32, 39, 62)' }}>
                        <CardTitle tag="div">
                            <h1 style={{ textAlign: 'left' }}>
                                <span style={{ color: '#3fdf14', fontWeight: 800 }}>GROWING</span><br />
                                YOUR BRAND<br />
                                ONLINE<br />
                            </h1>
                            <p style={{ textAlign: 'left' }}>
                                Our online marketing services are data driven and tailored to your individual needs, sending targeted traffic to your website. This generates qualified leads and grows your business.
                            </p>
                        </CardTitle>
                        <CardBody>
                            <CardFooter>

                            </CardFooter>
                        </CardBody>
                    </Card>
                </Col>
                <Col className="px-0" md="6" sm={6} lg={3}>
                    <Card
                        className="card-fashion card-background"
                        style={{
                            backgroundImage:
                                "url(assets/images/webp/growing-bg-1.webp)",
                            marginBottom: 0
                        }}
                    >
                        <CardTitle tag="div">
                            <h3>Expedite Qualified Leads</h3>
                            <p> Having a great looking website is only the first part of the process. Through our coordinated marketing strategies, covering a range of digital approaches, we generate relevant traffic to increase your conversion rates.</p>
                        </CardTitle>
                    </Card>
                </Col>
                <Col className="px-0" md="6" sm={6} lg={3}>
                    <Card
                        className="card-fashion card-background"
                        style={{
                            backgroundImage:
                                "url(assets/images/webp/growing-bg-2.webp)",
                            marginBottom: 0
                        }}
                    >
                        <CardTitle tag="div">
                            <h3>Convert Traffic to Leads and Customers   </h3>
                            <p> The next step is to converting visitors to leads and customers. We’ll make sure that your content and marketing strategies are engaging and use friendly for your intended audience. Calls to action and effective remarketing are part of our proven approach to increase conversions.</p>
                        </CardTitle>
                    </Card>
                </Col>
                <Col className="px-0" md="6" sm={6} lg={3}>
                    <Card
                        className="card-fashion card-background"
                        style={{
                            backgroundImage:
                                "url(assets/images/webp/growing-bg-3.webp)",
                            marginBottom: 0
                        }}
                    >
                        <CardTitle tag="div">
                            <h3>Develop Brand Loyalty and Customer Returns.</h3>
                            <p> Great design, content, functionality and remarketing strategies designed for your customers means that they stay engaged. It’s proven that sustained engagement leads to higher conversion rates, further securing the success of your business.</p>
                        </CardTitle>
                    </Card>
                </Col>

            </Row>
        </section>
    )
}