import React, { useState } from 'react'
export function ReadMore(props: any) {
    const [show, setShow] = useState(false) 
    if (show) {
        return (
            <>
                {props.children}
                <a href="#readmore" onClick={() => { setShow(false) }}>Read Less</a>
            </>
        )

    } else {
        return (
            <>
                <a href="#readmore" onClick={() => { setShow(true) }}>Read More</a>
            </>
        )
    }

}