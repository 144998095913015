import React from 'react'
import IndexNavbar from '../../../components/IndexNavbar' 
import { Footer } from '../../../components/Footer'
import MusicAndVideoHeader from './MusicAndVideoHeader'
import { Container, Row, Col, Card, CardHeader, CardBody } from 'reactstrap'
import {Helmet} from 'react-helmet'

export type MusicAndVideoProps = {}
export function MusicAndVideo(props: MusicAndVideoProps) {
    return (
        <>
        <Helmet>
        <title>Online Marketing | My Stores and More</title>
    <meta name="description" content="Complete Online Marketing and Brand Solutions"/>
        </Helmet>
            <IndexNavbar />
            <div className="wrapper"> 
            <MusicAndVideoHeader />
            <div className="main"> 
                    <section className="features-6" style={{   padding: ' 50px 0', color: '#fff' }}>
                        <Container>
                        <h3 style={{textAlign: 'center'}}>Increase Engagement with the Power of Music and Video </h3>
                            <Row>
                                <Col md={4}>
                                    <div className="info info-horizontal" >
                                        <div className="icon icon-info">
                                            <i className="now-ui-icons design-2_html5"></i>
                                        </div>
                                        <div className="description">
                                            <h5 className="info-title">Video Editing and Production  </h5>
                                            <p>Videos are the perfect way to increase consumer engagement and prolong visit time to your site. Videos provide are the next step in your complete online marketing strategy to grow your business. At My Stores and Mores our video specialists will produce and edit a video that won’t fail to impress your customers and keep encouraging click-throughs and conversions.</p>
                                        </div>
                                    </div> 
                                </Col>
                                <Col md={4}>
                                    <div className="phone-container">
                                        <img alt="..." src={"/assets/images/iphone2.08746fe1.png"}></img>
                                    </div>
                                </Col>
                                <Col md={4}> 
                                    <div className="info info-horizontal">
                                        <div className="icon icon-success">
                                            <i className="now-ui-icons files_single-copy-04"></i>
                                        </div>
                                        <div className="description">
                                            <h4 className="info-title">Script Writing and Sound Studio  </h4>
                                            <p>Making sure that your video is the best it can possibly be is our priority. We provide you with all the services you need to make this happen. Use our script writing services to convey aurally the message your stunning video imagery is transmitting. Partnered with our award-winning composer and producer we can provide the perfect music to support your product sales, and get customers on board.</p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            </Container>
                    </section>
                    <section className="features-6" style={{ background: 'rgb(32, 39, 62)', padding: ' 50px 0', color: '#fff' }}>
                        <Container>
                            <Row>
                                <Col md={12} style={{marginTop: 50}}>
                                    <h3 style={{ textAlign: "center", color: "rgb(63, 223, 20)", fontWeight: 800 }}><span style={{color: '#fff', fontWeight: 300}}>Your Questions</span> Answered</h3>
                                </Col>
                                <Col md={6}>
                                    <Card className="card-plain">
                                        <CardHeader style={{color: "rgb(63, 223, 20)", fontSize: 18}} id="headingOne" role="tab">
                                        Do I need to use video?
                                        </CardHeader> 
                                            <CardBody>
                                            Video is fast becoming the medium of choice to get your message across on line. Pictures and text can explain your products and service; video can demonstrate them in real time. Videos instantly captures your customers’ attention and embedded videos have become part of our every day online experience. Do you want to showcase your products and services to your customers in the very best way possible? Video is the answer.

</CardBody> 
                                    </Card>
                                    <Card className="card-plain">
                                        <CardHeader  style={{color: "rgb(63, 223, 20)", fontSize: 18}}  id="headingOne" role="tab">
                                        What about the music?
                                        </CardHeader> 
                                            <CardBody>
                                            Music selection is an essential part of producing the perfect video. Music is unique in the way in connects, and music perfectly matched to the imagery and ethos of your brand will have a dramatic effect on the quality of your video. We have a vast library of music composed for advertising purposes, or if you really want to connect to your customers and make your video stand out, why not commission your own original soundtrack? It’s all part of the My Stores and More service.


</CardBody> 
                                    </Card>
                                </Col>
                                <Col md={6}>
                                    <Card className="card-plain">
                                        <CardHeader   style={{color: "rgb(63, 223, 20)", fontSize: 18}}  id="headingOne" role="tab">
                                        How can I make a video for my business?
                                               
                                        </CardHeader> 
                                            <CardBody> 
                                            The My Stores and More team will guide you through every step, so even if this is your first promotional video, it will be a pain free and rewarding process. We’ll combine your knowledge of your business with our technical expertise and marketing know-how to produce outstanding videography that engages customers and increases your sales.


                                                </CardBody> 
                                    </Card>
                                    <Card className="card-plain">
                                        <CardHeader  style={{color: "rgb(63, 223, 20)", fontSize: 18}}  id="headingOne" role="tab">
                                        What are the marketing benefits of using video?
                                        </CardHeader> 
                                            <CardBody>
                                            Not only is it a great way to showcase what your company has to offer, there are a number of other great benefits of video that will improve your conversion rates. Video increases engagement time on your website. Increased engagement time leads to increased conversions, and it’s great for SEO rankings too. Research shows that videos produce high click through rates than still image adverts and are more effective at promoting repeat visits to your website. Videos linked to YouTube offer an extra dimension to your social media and online marketing and maximise your businesses exposure online.

                                            </CardBody>

</Card>
                                </Col> 
                            </Row>

                        </Container>
                    </section>

                    <Footer />
                </div>
            </div>
        </>
    )
}