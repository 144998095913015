import React from 'react'
import { StaticMasterTemplate } from '../StaticMasterTemplate'
import IndexHeader from '../../../components/IndexHeader'
import { Container, Row, Col } from 'reactstrap'
import { ReadMore } from '../../../components/ReadMore' 
import { TemplateClientContent } from '../client-content/TemplateClientContent'

export type BaliWebDesignProps = {}
export function BaliWebDesign(props: BaliWebDesignProps) {

    let header = <IndexHeader />
    return (
        <>
            <StaticMasterTemplate benner={header} >
                <section style={{ background: 'rgb(32, 39, 62)', padding: '50px 0' }}>
                    <Container>
                        <Row>
                            <Col>
                                <h1 style={{ textAlign: 'center' }}>Bali Web Design That Delivers Proven Results</h1>
                                <p style={{ fontWeight: 500 }}>Your business needs a stunning, functional website to get you noticed on line. You need a website that’s contemporary, looks great and works well for your customers including being fully mobile friendly – that’s where 80% of web searches take place. You also need a website that’s built with Search Engine Optimisation (SEO) in mind. When it comes to Bali web design, My Stores and More are experts in exactly this. With a portfolio of clients in Bali, they’ve built brilliant website and got those websites to exactly where they need to be – Google Page 1. My Stores and More provide full solutions for Web Design Bali, including SEO optimisation and digital and social media marketing strategies and packages. Contact us today and get your business ranked to Google Page 1!</p>
                                <ReadMore>
                                    <p>When deciding on a website developer it is important to keep in mind a few things:</p>
                                    <h3>Web Design That Is Affordable And Delivered On Time</h3>
                                    <p>Just because something looks cheap does not mean it is a good deal. In fact 90% of the time it is far from it! If you are like most entrepreneurs you have invested probably 10s of thousands of dollars in to setting up your business (if not more) let alone the countless hours. It’s important to invest in quality solutions providing long term sustainable growth for your business. If the internet is by far the best tool for growing your business today why would anybody think the cheapest you can get a website for is the best solution to your business growth? We have seen some horror stories over the years where business owners have chosen the cheap option and spent the next 12 months trying to get their website right. The apparently cheap solution has meant they’ve lost potential income and increased their workload  because what they thought was cheap just didn’t work! My Store and More  have a proven track record of building quality websites that work and delivery results. We have worked with some of the largest names in retail globally and some incredible start-ups. My Stores and More build once, do it well and it works.</p>
                                    <p>Never before in history has it been so easy to develop your idea into a business. Just because you build a website, that  doesn’t mean you will be seen.  Successful entrepreneurs know that building a brand takes time, effort and a little money of course. We have many clients ranked on page 1 of Google and from that has literally turned their business around.  Today if you’re not on page 1 of Google you are only reaching a very small percentage of your potential business.</p>
                                    <h3>Web Design Bali By Experts With A Proven Track Record</h3>
                                    <p>My Stores and More are the industry leaders for Bali web design. With years of experience, and a wide ranging portfolio of clients, including some Bali’s leading businesses, choosing My Stores and More as your Bali web designer is the best choice you can make if you want to see your online exposure grow and get more qualified leads and conversions.  Your website is the public face of your business. Whatever line of business you’re in, you need a website that’s appealing to your customers, works well and reflects the character of your business. Combine great website design with and effective online marketing strategy and you have an unbeatable formula for success.</p>
                                    <p>Contemporary website design, a focused SEO strategy and effective and engaging online marketing campaign is proven as the effective way to build your business. Getting your design right is the important first stage; this will capture your customers’ attention and then get to to stay on your website. Great design and calls to action will keep your customers engaged and the longer the engagement, the more likely you are to get a conversion, and that’s what it is all about. From your main site, to landing pages for specific campaigns My Stores and More has you covered.</p>
                                    <p>Being creative is all part of our packages for web design Bali. Our team of graphic design specialists deliver sensational individualised designs, creating the ultimate image for your business. Our creative designs will not only look stunning and stylish, to grab your customers’ attention, they’ll also build your brand image and make you stand out form the crowd. As experts in web design Bali we take a collaborative approach. Our creative team work with our technical staff from the outset. This means that we’ll make sure you’re absolutely looking your best online and your designs work – for you, your customers, and get notice by Google so you get ranked!</p>
                                    <p>Now that you have your online image sorted and your website is appealing both visually and in terms of functionality to your customers, it’s time to get get ranked and get noticed by Google too. The great news is that for any  web design, My Stores and More will have had this in mind from the very start of the design process.  SEO requires a multifaceted approach. We guarantee to get you noticed on Google –  just check out how many of our clients are on page 1! SEO isn’t a one off or overnight process though. It takes time to build your domain authority so that Google knows you’re worthy of that Page 1 spot. That doesn’t mean you need to worry about being locked in to long term contracts however; we let our work speak for itself. SEO is about longevity; it’s an investment that ensures you’re going to get seen on line. Why allow your competitors to outrank you, when you can be the one to be seen first? After all, you’re the best at what you do.</p>
                                    <p>Social media has changed the way we communicate and the way in which businesses connect with their customers. You need to be harnessing the power of social media to benefit your business. My Stores and More will design and run Facebook campaigns that target the right people for you and your business. Specially designed ads, with a specific target audience mean that you’re effectively and efficiently reaching potential new customers. Once it’s working, we can scale this campaign, making sure you’re getting the best value for every dollar you spend.</p>
                                    <p>Google Pay Per Click advertising means that you can get your business onto Google Page 1 instantly. However, it’s a complex campaign to manage so we’ll run the software and analytics for you and make this completely hassle free for you. This can be a great option to increase your online exposure while your SEO strategy is building your organic ranking. My Stores and More online marketing solutions are data driven. We carry out thorough research on the best search terms for you to chase and your competitors. This data driven approach reduces the element of chance, and means that we’re always ready to give you feedback on exactly how your campaign is going.</p>
                                    <h3>Why Choose My Stores And More For Your Bali Web Design</h3>
                                    <p>My Stores and More provide copy and content writing services as part of your package. It’s essential that you get your message across clearly in a tone of voice that connects with your customers. Persuasive and engaging copywriting creates a voice for your business and this is increase sales. Creating a unique voice for your business that connects strongly with your customer base is all part of building your brand, and of course your profits. You need to get your message across clearly and concisely, without missing out on any of that important information which makes your company stand out from the crowd. Calls to action, hot buttons, descriptions – we’ll take care of them all and your customers won’t be able to resist clicking through. My Store and Mores copywriters are industry experts at delivering your message in a manner that’s engaging to read and also gets noticed by Google.</p>
                                    <p>You need to be online, and you need to be on Google Page 1. Don’t confuse a short term bargain with a good value long term investment in your business. Contact My Stores and More today and reap the rewards of their experience and the benefits of the online world.</p>
                                </ReadMore>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section style={{ padding: '50px 0' }}>
                    <Container>
                        <Row>
                            <Col>
                                <h4 style={{ textAlign: "center", marginTop: 0 }}>We work with businesses to help them grow from an idea to a successful business through hard work, investment in a great website and a marketing plan that works every time. Check out our work for some of our clients:</h4>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <TemplateClientContent
                    style={{ background: 'rgb(32, 39, 62)', padding: '50px 0 0 0 ' }}
                    imageUrl="/assets/images/client-png/bali-web-design-baliquardbike.png"
                    imageAltText="Quad Biking Bali"
                    imagePosition="RIGHT"
                    textPaddingTop={50}
                >
                    <h3>Quad Biking Bali</h3>
                    <p>ATV quad biking has become the next big thing to do in Bali. With growth however comes competition. Quad Biking Bali approached us as they just weren’t getting enough business relying on traditional marketing methods such as leaving brochures at venues around the island.</p>
                    <p>Initially they had designed their own website using a do it yourself WIX platform. Unfortunately it didn’t work and the opportunity loss was mounting up!</p>
                </TemplateClientContent>
                <TemplateClientContent
                    style={{ background: 'url("/assets/images/client-png/bg1.jpg"', padding: '20px 0 50px 0', backgroundPosition: 'top', backgroundSize: 'cover' }}
                    imageUrl="/assets/images/client-png/amo.png"
                    imageAltText="AMO Spa Bali"
                    imagePosition="LEFT"
                    textPaddingTop={130}
                >
                    <h3>AMO Spa Bali</h3>
                    <p>AMO Spa has an incredible reputation on the island due to outstanding salons and great customer service. Their word of mouth referrals are exceptional, however they knew they weren’t seeing their potential from the thousands of online searches for Bali salons.</p>
                </TemplateClientContent>
                <TemplateClientContent
                    style={{ padding: '50px 0 0 0 ' }}
                    imageUrl="/assets/images/client-png/bvam.png"
                    imageAltText="Bali Villas and More "
                    imagePosition="RIGHT"
                    textPaddingTop={50}
                >
                    <h3>Bali Villas and More </h3>
                    <p>Bali Villa and More realized an opportunity in the ever increasing Bali villa accommodation market. And with the owners’ strong travel experience in Bali over 10 years they decided to commence their journey with a high-end, sophisticated website.</p>
                </TemplateClientContent>
                <TemplateClientContent
                    style={{  background: 'url("/assets/images/client-png/bg2.jpg"', backgroundPosition: 'top', backgroundSize: 'cover' , padding: '50px 0 0 0 ' }}
                    imageUrl="/assets/images/client-png/bali-wedding-singer.png"
                    imageAltText="Bali Wedding Singe5"
                    imagePosition="LEFT"
                    textPaddingTop={50}
                >
                    <h3>Bali Wedding Singe</h3>
                    <p>Athron McCann has lived and worked in Bali for many years. He had the foresight to realize the increasing demand for entertainment at Bali weddings many years ago. We worked with Athron to produce the website Bali Wedding Singer.</p>
                </TemplateClientContent>
                <TemplateClientContent
                    style={{ background: 'rgb(32, 39, 62)', padding: '0px 0 50px 0 ' }}
                    imageUrl="/assets/images/client-png/isaoparis.png"
                    imageAltText="ISAO Paris"
                    imagePosition="RIGHT"
                    textPaddingTop={200}
                >
                    <h3>ISAO Paris</h3>
                    <p>ISAO Paris is owned and operated by French designer residing in Bali, Isabelle Olivia. Olivia has an incredible array of women’s fashion designed with her French background and manufactured locally here in Bali. After years of web designers trying to get her website right and her website being hacked she came to us for a professional makeover.</p>
                </TemplateClientContent>
            </StaticMasterTemplate>
            
        </>
    )
}