/*eslint-disable*/
import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import Slider from "react-slick";

type OurTeamHeaderProps = { 
}

function OurTeamHeader(props: OurTeamHeaderProps ) {
  let pageHeader = React.createRef<HTMLDivElement>()
  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current!.style.transform = "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    //autoplay: true,
  };

  return (
    <>
      <div className="page-header clear-filter" filter-color="black" >
        <div
          className="page-header-image"
          style={{ backgroundImage: "url(https://storage.googleapis.com/mystoresandmore/website/banner-online-marketing.jpg)", backgroundSize: 'cover' }}
          ref={pageHeader}
        />
        <Container>
          <Slider {...settings}>
          <Row>
              <Col >
                <img src="https://storage.googleapis.com/mystoresandmore/website/team.png" style={{float: 'left'}} alt={"wawan"} />
               </Col>
               <Col style={{textAlign: 'left', marginTop: '20%'}}>
                <p  >Website Design, Online Marketing,<br />
                  & Mobile App Development with</p>
                  <h2 style={{ color: "#3fdf14", textTransform: "uppercase", fontWeight: 800, fontSize: 36, margin: 0 }}>Stunning Results</h2>
                  <p>We are a digital marketing agency focused on creative and results-driven solutions for companies ranging from start-ups to Fortune 1</p>
              </Col>
            </Row>
          </Slider>
        </Container>
      </div>
    </>
  );
}

export default OurTeamHeader;
