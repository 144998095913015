import React  from 'react'
import {  Row, Col } from 'reactstrap'
import { PortfolioCard  } from './PortfolioCard'
import { portfolioDataType } from './Portfolio'
export type PortfolioCardsProps = {
    category: string,
    portFoliosData: portfolioDataType[],
    onClick: (cat: string) => any
} 
export function PortfolioCards(props: PortfolioCardsProps) { 
    let Portfolios: any[] = []

    for (let i of props.portFoliosData) {
        
        Portfolios.push(
            <Col ley={i.title} md={4}>
                <PortfolioCard {...i} onClick={props.onClick } />
            </Col>
            
        )
    } 
    return (
        <>
            <Row style={{marginTop: 30}}>
                {Portfolios}
            </Row>
        </>
    )
}