import React from 'react'
import IndexNavbar from '../../components/IndexNavbar'
import IndexHeader from '../../components/IndexHeader'
import { Footer } from '../../components/Footer'
export type ServicesProps = {}
export function Services(props: ServicesProps) {
    return (
        <>
            <IndexNavbar />
            <div className="wrapper">
                <IndexHeader />
                <div className="main">
                    <h1>Services</h1>



                    <Footer />
                </div>
            </div>
        </>
    )
}