import React from 'react'
import { Container, Row, Col } from 'reactstrap';
import { useHistory } from 'react-router-dom'
export type OurProjectsProps = {}
export function OurProjects(props: OurProjectsProps) {
    let history = useHistory()
    return (
        <section style={{ background: 'rgb(32, 39, 62)', padding: '100px 0 50px 0' }}>
        <Container>
            <Row style={{ textAlign: 'center' }}><h3 style={{ textTransform: 'uppercase', width: '100%' }}>Our <span style={{ color: '#2c8e25', fontWeight: 600 }}>Projects</span> </h3></Row>
            <Row>
                <Col md={6}>
                    <Row>
                        <Col md={6} style={{ padding: 5 }}>
                            <div onClick={() => history.push("/portfolio")}>
                                <div className="porfolio-content" style={{ height: 250, background: "#424f6d", width: '100%' }}>
                                    <img src="https://storage.googleapis.com/mystoresandmore/website/creative-design.png" alt="..." />
                                </div>
                            </div>
                        </Col>
                        <Col md={6} style={{ padding: 5 }}>
                            <div  onClick={() => history.push("/portfolio")}>
                                <div className="porfolio-content" style={{ height: 250, background: "url('/assets/images/content-writing-bg.jpg')", width: '100%', backgroundSize: 'cover', backgroundPosition: 'center' }}>

                                </div>
                            </div>
                        </Col>
                        <Col md={12} style={{ padding: 5 }}>
                            <div  onClick={() => history.push("/portfolio")}>
                                <div className="porfolio-content" style={{ height: 250, background: "url('/assets/images/online-marketing-bg.jpg')", width: '100%', backgroundSize: 'cover', backgroundPosition: 'center' }}>

                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col md={6} style={{ padding: 5 }}>
                    <div  onClick={() => history.push("/portfolio")}>
                        <div className="porfolio-content" style={{ height: 510, background: "#424f6d", width: '100%', textAlign: "center" }}>
                            <p style={{
                                fontSize: 32, lineHeight: 1, paddingTop: '10%' 
                            }}>
                            FULLY <br/>
RESPONSIVE<br/>
WEBSITE <span style={{color: "rgb(44, 142, 37)", fontWeight: 800}}>DESIGN</span>
                            </p>
                            <img src="https://storage.googleapis.com/mystoresandmore/website/web-design.png" width={400} alt=".." />
                        </div>
                    </div>
                </Col>
                <Col md={3} style={{ padding: 5 }}>
                    <div  onClick={() => history.push("/portfolio")}>
                        <div className="porfolio-content" style={{ height: 250, background: "#424f6d", width: '100%' }}>
                            <img src="https://storage.googleapis.com/mystoresandmore/website/geo-targeting.png" alt="" />
                        </div>
                    </div>
                </Col>
                <Col md={6} style={{ padding: 5 }}>
                    <div  onClick={() => history.push("/portfolio")}>
                        <div className="porfolio-content" style={{ height: 250, background: "url('/assets/images/video-editing-bg.jpg')", width: '100%', backgroundSize: 'cover', backgroundPosition: 'center' }}>

                        </div>
                    </div>
                </Col>
                <Col md={3} style={{ padding: 5 }}>
                    <div  onClick={() => history.push("/portfolio")}>
                        <div className="porfolio-content" style={{ height: 250, background: "#424f6d", width: '100%' }}>
                        <img src="https://storage.googleapis.com/mystoresandmore/website/content-writing.png" alt="" />
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
    )
}