import React from 'react'
import IndexNavbar from '../../components/IndexNavbar'
import { Helmet } from "react-helmet";

import { Container, Row, Col, Card, CardBody, CardTitle, Form, InputGroup, InputGroupAddon, InputGroupText, Input, CardFooter, Button } from 'reactstrap'
import { Footer } from '../../components/Footer';
export type Page404Props = {}
export function Page404(props: Page404Props) {
    const [firstFocus, setFirstFocus] = React.useState(false);
    const [lastFocus, setLastFocus] = React.useState(false);
    const [emailFocus, setEmailFocus] = React.useState(false);
    React.useEffect(() => {
        document.body.classList.add("signup-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        return function cleanup() {
            document.body.classList.remove("signup-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });
    return (
        <>
            <IndexNavbar />
            <div className="page-header header-filter" filter-color="black">
                <div
                    className="page-header-image"
                    style={{
                        backgroundImage: "url(/assets/images/banner-background.jpg)"
                    }}
                ></div>
                <div className="content">
                    <Container>
                        <Row>
                            <Col Col className="ml-auto mr-auto" md="6" lg="4"> 
                                <h4 style={{textAlign: 'left', margin: 0}}>Ooops... Error 404, We are sorry, but the page you are looking for does not exist. </h4>
                                <div className="info info-horizontal" style={{ padding: 0 }}>
                                    <div className="icon icon-info">
                                        <i className="now-ui-icons media-2_sound-wave"></i>
                                    </div>
                                    <div className="description">
                                        <h5 className="info-title">Marketing</h5>
                                        <p className="description">
                                            We've created the marketing campaign of the website. It
                                            was a very interesting collaboration.
                    </p>
                                    </div>
                                </div>
                                <div className="info info-horizontal" style={{ padding: 0 }}>
                                    <div className="icon icon-info">
                                        <i className="now-ui-icons media-1_button-pause"></i>
                                    </div>
                                    <div className="description">
                                        <h5 className="info-title">Fully Coded in React 16</h5>
                                        <p className="description">
                                            We've developed the website with React 16 and CSS3. The
                                            client has access to the code using GitHub.
                    </p>
                                    </div>
                                </div>
                                <div className="info info-horizontal" style={{ padding: 0 }}>
                                    <div className="icon icon-info">
                                        <i className="now-ui-icons users_single-02"></i>
                                    </div>
                                    <div className="description">
                                        <h5 className="info-title">Built Audience</h5>
                                        <p className="description">
                                            There is also a Fully Customizable CMS Admin Dashboard for
                                            this product.
                    </p>
                                    </div>
                                </div>
                            </Col>
                            <Col className="mr-auto" md="6" lg="4">
                                <Card className="card-signup" >
                                    <CardBody>
                                        <CardTitle className="text-center" tag="h4"> Get In Touch </CardTitle>
                                        <div className="social text-center">
                                            <Button
                                                className="btn-icon btn-round mr-2"
                                                color="twitter"
                                            >
                                                <i className="fab fa-twitter"></i>
                                            </Button>
                                            <Button
                                                className="btn-icon btn-round mr-2"
                                                color="dribbble"
                                            >
                                                <i className="fab fa-dribbble"></i>
                                            </Button>
                                            <Button className="btn-icon btn-round" color="facebook">
                                                <i className="fab fa-facebook"></i>
                                            </Button>
                                            <h5 className="card-description">or be classical</h5>
                                        </div>
                                        <Form action="" className="form" method="">
                                            <InputGroup
                                                className={firstFocus ? "input-group-focus" : ""}
                                            >
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="now-ui-icons users_circle-08"></i>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    autoComplete="fullname"
                                                    placeholder="First Name..."
                                                    type="text"
                                                    onFocus={() => setFirstFocus(true)}
                                                    onBlur={() => setFirstFocus(false)}
                                                ></Input>
                                            </InputGroup>
                                            <InputGroup
                                                className={lastFocus ? "input-group-focus" : ""}
                                            >
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="now-ui-icons text_caps-small"></i>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    autoComplete="name"
                                                    placeholder="Last Name..."
                                                    type="text"
                                                    onFocus={() => setLastFocus(true)}
                                                    onBlur={() => setLastFocus(false)}
                                                ></Input>
                                            </InputGroup>
                                            <InputGroup
                                                className={emailFocus ? "input-group-focus" : ""}
                                            >
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="now-ui-icons ui-1_email-85"></i>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    autoComplete="email"
                                                    placeholder="Your Email..."
                                                    type="text"
                                                    onFocus={() => setEmailFocus(true)}
                                                    onBlur={() => setEmailFocus(false)}
                                                ></Input>
                                            </InputGroup>

                                            <CardFooter className="text-center">
                                                <Button
                                                    className="btn-round"
                                                    color="info"
                                                    href="#pablo"
                                                    onClick={e => e.preventDefault()}
                                                    size="lg"
                                                >
                                                    Contact Us
                        </Button>
                                            </CardFooter>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer type="SIMPLE"></Footer>
            </div>
            <Helmet>
                <style>
                    {
                        `
                        html, body {
                            background: #20273e;
                        }
                        `
                    }
                </style>
            </Helmet>
        </>
    )
}