/*eslint-disable*/
import React from "react";
import { Container, Row, Col, } from "reactstrap";
import Slider from "react-slick";

function WebDesignHeader() {
  let pageHeader = React.createRef<HTMLDivElement>()
  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current!.style.transform = "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    //autoplay: true,
  };

  return (
    <>
      <div className="page-header clear-filter" filter-color="black" >
        <div
          className="page-header-image"
          style={{ backgroundImage: "url('https://storage.googleapis.com/mystoresandmore/website/banner-online-marketing.jpg')", backgroundSize: 'cover' }}          
          ref={pageHeader}
        />
        <Container>
          <Slider {...settings}>
            <div>
              <Row>
                <Col md={6}>
                  <img src="https://storage.googleapis.com/mystoresandmore/website/web-design.png" style={{ float: 'left' }} alt={"wawan"} />
                </Col>
                <Col md={6} style={{ textAlign: 'left', marginTop: '10%' }} >
                  <h2 style={{ color: "#3fdf14", textTransform: "uppercase", fontWeight: 800, fontSize: 36, margin: 0 }}>Web Design </h2>
                  <h4>Visually stunning and functional websites that get your business noticed on line </h4>
                  <ul>
                    <li>Websites that promote customer engagement and lead to higher conversions </li>
                    <li>Fully integrated marketing strategies and landing page design to capture your customers’ interest. </li>
                    <li>Ecommerce and App design to increase your profits </li>
                  </ul>
                </Col>
              </Row>
            </div>
             
          </Slider>
        </Container>
      </div>
    </>
  );
}

export default WebDesignHeader;
