import React from 'react'
import IndexNavbar from '../../components/IndexNavbar'
import { Container, Row, Col, Card, CardBody, CardTitle, Button, Alert, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap'
import Helmet from 'react-helmet'
import { Footer } from '../../components/Footer'
import { SocialMedia } from '../../components/SocialMedia'
import { MainContext } from '../../App'
type formType = {
    name?: string,
    email?: string,
    business?: string,
    phone?: string,
    country?: string,
    message?: string
}
export type ContactUsProps = {}
export function ContactUs(props: ContactUsProps) {
    let cnf: formType = {
        name: '',
        email: '',
        business: '',
        phone: '',
        country: '',
        message: ''
    }
    const [formSubmit, setFormSubmit] = React.useState(cnf)
    const [formSubmited, setFormSubmited] = React.useState(false)
    const [errInput, setErrInput] = React.useState(false);
    const [nameFocus, setNameFocus] = React.useState(false);
    const [phoneFocus, setPhoneFocus] = React.useState(false)
    const [countryFocus, setCountryFocus] = React.useState(false)
    const [businessNameFocus, setBusinessNameFocus] = React.useState(false)
    const [emailFocus, setEmailFocus] = React.useState(false);
    React.useEffect(() => {
        document.body.classList.add("signup-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        return function cleanup() {
            document.body.classList.remove("signup-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });
    return (
        <MainContext.Consumer>
            {
                c => {
                    if (!c) {
                        return (<h1>Tidak ada contenct</h1>)
                    }
                    const firebaseApp = c.firebaseApp;
                    return (
                        <>
                            <IndexNavbar />
                            <div className="page-header header-filter" filter-color="black">
                                <div
                                    className="page-header-image"
                                    style={{
                                        backgroundImage: "url(/assets/images/banner-background.jpg)"
                                    }}
                                ></div>
                                <div className="content">
                                    <Container>
                                        <Alert color="info" isOpen={formSubmited}>
                                            <div className="container">
                                                <div className="alert-icon">
                                                    <i className="now-ui-icons ui-2_like"></i>
                                                </div>
                                                <strong>Thank you!</strong> We appreciate you contacting My Stores and More. One of our colleagues will get back in touch with you soon! Have a great day! <button
                                                    type="button"
                                                    className="close"
                                                    aria-label="Close"
                                                    onClick={() => setErrInput(false)}
                                                >
                                                    <span aria-hidden="true">
                                                        <i className="now-ui-icons ui-1_simple-remove"></i>
                                                    </span>
                                                </button>
                                            </div>
                                        </Alert>
                                        <Alert color="warning" isOpen={errInput}>
                                            <div className="container">
                                                <div className="alert-icon">
                                                    <i className="now-ui-icons travel_info"></i>
                                                </div>
                                                <strong>Almost done!</strong> Please complete the form <button
                                                    type="button"
                                                    className="close"
                                                    aria-label="Close"
                                                    onClick={() => setErrInput(false)}
                                                >
                                                    <span aria-hidden="true">
                                                        <i className="now-ui-icons ui-1_simple-remove"></i>
                                                    </span>
                                                </button>
                                            </div>
                                        </Alert>
                                        <Row>
                                            <Col Col className="ml-auto mr-auto" md="6" lg="4">
                                                <div className="info info-horizontal" style={{ padding: 0 }}>
                                                    <div className="icon icon-info">
                                                        <i className="now-ui-icons tech_headphones"></i>
                                                    </div>
                                                    <div className="description">
                                                        <h5 className="info-title">Call Us</h5>
                                                        <p className="description">
                                                            AU: 1300 520 212
                    </p>
                                                    </div>
                                                </div>
                                                <div className="info info-horizontal" style={{ padding: 0 }}>
                                                    <div className="icon icon-info">
                                                        <i className="now-ui-icons location_pin"></i>
                                                    </div>
                                                    <div className="description">
                                                        <h5 className="info-title">Address</h5>
                                                        <p className="description">
                                                            14/380 St Kilda Rd,<br />
                                                            Melbourne VIC 3004, Australia
                    </p>
                                                    </div>
                                                </div>
                                                <div className="info info-horizontal" style={{ padding: 0 }}>
                                                    <div className="icon icon-info">
                                                        <i className="now-ui-icons ui-1_email-85"></i>
                                                    </div>
                                                    <div className="description">
                                                        <h5 className="info-title">Email Us</h5>
                                                        <p className="description">
                                                            <a href="mailto:info@mystoresandmore.com" >info@mystoresandmore.com</a> <br />
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="info info-horizontal" style={{ padding: 0 }}>
                                                    <div className="icon icon-info">
                                                        <i className="now-ui-icons ui-2_like"></i>
                                                    </div>
                                                    <div className="description">
                                                        <h5 className="info-title">Follow Us </h5>
                                                        <p className="description">
                                                            <div className="social text-center">
                                                                <SocialMedia />

                                                            </div>
                                                        </p>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col className="mr-auto" md="6" lg="4">
                                                <Card className="card-signup" >
                                                    <CardBody>
                                                        <CardTitle className="text-center" tag="h4"> Get In Touch </CardTitle>
                                                        <InputGroup
                                                            className={nameFocus ? "input-group-focus" : ""}
                                                        >
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <i className="now-ui-icons users_circle-08"></i>
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                autoComplete="fullname"
                                                                placeholder="Your Name..."
                                                                type="text"
                                                                onFocus={() => setNameFocus(true)}
                                                                onBlur={() => setNameFocus(false)}
                                                                onChange={
                                                                    (evt: any) => {
                                                                        const val = evt.target.value
                                                                        let frm = formSubmit
                                                                        frm.name = val
                                                                        setFormSubmit(frm)
                                                                    }
                                                                }
                                                            ></Input>
                                                        </InputGroup>
                                                        <InputGroup
                                                            className={businessNameFocus ? "input-group-focus" : ""}
                                                        >
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <i className="now-ui-icons business_briefcase-24"></i>
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                autoComplete="fullname"
                                                                placeholder="Your Business Name..."
                                                                type="text"
                                                                onFocus={() => setBusinessNameFocus(true)}
                                                                onBlur={() => setBusinessNameFocus(false)}
                                                                onChange={
                                                                    (evt: any) => {
                                                                        const val = evt.target.value
                                                                        let frm = formSubmit
                                                                        frm.business = val
                                                                        setFormSubmit(frm)
                                                                    }
                                                                }
                                                            ></Input>
                                                        </InputGroup>
                                                        <InputGroup
                                                            className={emailFocus ? "input-group-focus" : ""}
                                                        >
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <i className="now-ui-icons ui-1_email-85"></i>
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                autoComplete="fullname"
                                                                placeholder="Your Email..."
                                                                type="text"
                                                                onFocus={() => setEmailFocus(true)}
                                                                onBlur={() => setEmailFocus(false)}
                                                                onChange={
                                                                    (evt: any) => {
                                                                        const val = evt.target.value
                                                                        let frm = formSubmit
                                                                        frm.email = val
                                                                        setFormSubmit(frm)
                                                                    }
                                                                }
                                                            ></Input>
                                                        </InputGroup>
                                                        <InputGroup
                                                            className={phoneFocus ? "input-group-focus" : ""}
                                                        >
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <i className="now-ui-icons tech_mobile"></i>
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                autoComplete="fullname"
                                                                placeholder="Your Phone Number..."
                                                                type="text"
                                                                onFocus={() => setPhoneFocus(true)}
                                                                onBlur={() => setPhoneFocus(false)}
                                                                onChange={
                                                                    (evt: any) => {
                                                                        const val = evt.target.value
                                                                        let frm = formSubmit
                                                                        frm.phone = val
                                                                        setFormSubmit(frm)
                                                                    }
                                                                }
                                                            ></Input>
                                                        </InputGroup>
                                                        <InputGroup
                                                            className={countryFocus ? "input-group-focus" : ""}
                                                        >
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <i className="now-ui-icons objects_globe"></i>
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                autoComplete="fullname"
                                                                placeholder="Your Country..."
                                                                type="text"
                                                                onFocus={() => setCountryFocus(true)}
                                                                onBlur={() => setCountryFocus(false)}
                                                                onChange={
                                                                    (evt: any) => {
                                                                        const val = evt.target.value
                                                                        let frm = formSubmit
                                                                        frm.country = val
                                                                        setFormSubmit(frm)
                                                                    }
                                                                }
                                                            ></Input>
                                                        </InputGroup>
                                                        <Input
                                                            rows="3"
                                                            type="textarea"
                                                            placeholder="Your Message"
                                                            style={{ padding: '0 15px' }}
                                                            onChange={
                                                                (evt: any) => {
                                                                    const val = evt.target.value
                                                                    let frm = formSubmit
                                                                    frm.message = val
                                                                    setFormSubmit(frm)
                                                                }
                                                            }
                                                        ></Input>
                                                        <div style={{ marginTop: 15 }}>

                                                            <Button
                                                                color="info"
                                                                type="button"
                                                                className="btn-round"
                                                                style={{ width: '100%' }}
                                                                onClick={() => {
                                                                    //const posData = PosData(surveyData, Moment(surveyDate).format("Do MMM YYYY"), surveyTime, "[First Home Boost QUALIFY] QUALIFED TO RECEIVE AN ADDITIONAL $10,000")
                                                                    if (formSubmit.business !== '' && formSubmit.country !== '' && formSubmit.email !== '' && formSubmit.name !== '' && formSubmit.phone !== '' && formSubmit.message !== '') {
                                                                        var newPostKey = firebaseApp.database().ref().child('contact-form').push().key;
                                                                        firebaseApp.database().ref('contact-form/' + newPostKey).set(formSubmit).then(
                                                                            d => {
                                                                                // setModalOpen(false)
                                                                                setFormSubmited(true)
                                                                            }
                                                                        ).catch(
                                                                            e => {
                                                                                console.log("Masuk Error", e)
                                                                            }
                                                                        )
                                                                    } else {
                                                                        setErrInput(true)
                                                                    }

                                                                }}
                                                            > Request Now </Button>

                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                                <Footer type="SIMPLE"></Footer>
                            </div>
                            <Helmet>
                                <style>
                                    {
                                        `
                        html, body {
                            background: #20273e;
                        }
                        `
                                    }
                                </style>
                            </Helmet>
                        </>
                    )
                }
            }
        </MainContext.Consumer>
    )
}