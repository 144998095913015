import React from 'react';
import ReactDOM from 'react-dom'; 
import App from './App';
import * as serviceWorker from './serviceWorker';


import * as firebase from 'firebase';
import { SecurityData } from './shared/SecurityData'

const runBootStrap = () => {
    fetch('/__/firebase/init.json').then(response => {
        response.json().then(firebaseConfig => {
            let firebaseApp: firebase.app.App = null!;
            if (!firebase.apps || firebase.apps.length === 0) {
                if (!firebaseConfig) {
                    alert('Firebase config not found in application. please report this problem to your admin');
                }
                firebaseApp = firebase.initializeApp(firebaseConfig);

            } else {
                firebaseApp = firebase.apps[0];
            }
            let called = false;
            // window['firebaseApp'] = firebaseApp;
            const unSubscribe = firebaseApp.auth().onAuthStateChanged((user: SecurityData.FirabaseAuthUser | any) => {
                if (called) {
                    return;
                }
                if (user) {

                    called = true;
                    ReactDOM.render(<App firebaseApp={firebaseApp} />, document.getElementById('root'));
                    unSubscribe();
                } else {
                    // console.log("masuk tidak ada user")
                    console.error('user null. berarti login di sini');
                    firebaseApp.auth().signInAnonymously()
                        .then(d => {
                            console.log('User data: ', d);
                        }).catch(exc => {
                            console.error('Gagal login, error: ', exc);
                        });
                }
            });
        });
    })
}

runBootStrap();

serviceWorker.unregister();
