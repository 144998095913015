import React from 'react'
import IndexNavbar from '../../components/IndexNavbar' 
import { Footer } from '../../components/Footer'
import { Testimonials } from '../../components/Testimonials'
import { HomeIndustries } from '../home-page/HomeIndustries'
import { GrowBusniness } from '../home-page/GrowBusniness'
import { DownloadAtoZ } from '../../components/DownloadAtoZ'

export type StaticMasterTemplateProps = {
    benner: React.ReactElement,
    children?: any
}
export function StaticMasterTemplate(props: StaticMasterTemplateProps) {
    return (
        <>
            <IndexNavbar />
            <div className="wrapper">
                {props.benner}
                <div className="main">
                     
                    {props.children}
                </div>
                <DownloadAtoZ />
                <Testimonials />
                <HomeIndustries />
                <GrowBusniness />
                <Footer />
            </div>
        </>
    )
}