import React from 'react'
import IndexNavbar from '../../components/IndexNavbar'
import IndexHeader from '../../components/IndexHeader'
import { Footer } from '../../components/Footer'
export type SiteMapProps = {}
export function SiteMap(props: SiteMapProps) {
    return (
        <>
            <IndexNavbar />
            <div className="wrapper">
                <IndexHeader />
                <div className="main">
                    <h1>SiteMap</h1>


                    <Footer />
                </div>
            </div>
        </>
    )
}