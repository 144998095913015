import React from 'react'
import IndexNavbar from '../../../components/IndexNavbar' 
import {Helmet} from 'react-helmet'
import { Footer } from '../../../components/Footer' 
import { Container, Row, Col, Card, CardHeader, CardBody } from 'reactstrap'
import ContentWritingHeader from './ContentWritingHeader'
export type ContentWritingProps = {}
export function ContentWriting(props: ContentWritingProps) {
    return (
        <>
            <Helmet>
        <title>Content Writing  | My Stores and More</title>
    <meta name="description" content="Complete Online Marketing and Brand Solutions"/>
        </Helmet>
            <IndexNavbar />
            <div className="wrapper">
                <ContentWritingHeader />
                <div className="main">
                    <section style={{ background: 'rgb(32, 39, 62)', padding: '50px 0 50px 0' }}>
                        <Container>
                            <Row>
                                <Col md={2}></Col>
                                <Col md={2}>
                                    <img style={{ marginTop: '20%' }} src="/assets/images/logo-white.png" alt="" />
                                </Col>
                                <Col md={7}>
                                    <h5>Professionally Written Content to Capture and Convey Your Businesses’ Message </h5>
                                    <p>My Stores and More professional copywriting captures the tone of your business, creating the ultimate voice for your website. We will take the time to get to know you and your customers so that your website speaks in a tone and language that connects to both your new and existing customers. This personalized approach gives you one less thing to worry about and your business more sales.</p>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section className="features-6" style={{ padding: ' 50px 0', color: '#fff' }}>
                        <Container>

                            <Row>
                                <Col md={4}>
                                    <div className="info info-horizontal" style={{paddingTop: 50}}>
                                        <div className="icon icon-info">
                                            <i className="now-ui-icons design-2_html5"></i>
                                        </div>
                                        <div className="description">
                                            <h5 className="info-title">Web Content</h5>
                                            <p>My Stores and More copywriters deliver website content in a tone of voice that connects to your customers. Memorable and persuasive, copywriting personalized to your brand is proven to improve sales and profits. We create a voice that resonates with your customers, instilling who you are and what you do firmly in memories. Return visits to your website convert to increased sales.</p>
                                        </div>
                                    </div>
                                    <div className="info info-horizontal">
                                        <div className="icon icon-danger">
                                            <i className="now-ui-icons design_palette"></i>
                                        </div>
                                        <div className="description">
                                            <h4 className="info-title">Landing Page Content</h4>
                                            <p>The message on your landing pages needs to be clear, concise, and convincing. Trust the My Stores and More copywriting team to provide the content for banners, hot buttons and descriptions. Your customers won’t be able to resist clicking through to your great products and services.</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="phone-container">
                                        <img alt="..." src={"/assets/images/iphone2.08746fe1.png"}></img>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="info info-horizontal"  style={{paddingTop: 50}}>
                                        <div className="icon icon-primary">
                                            <i className="now-ui-icons design-2_ruler-pencil"></i>
                                        </div>
                                        <div className="description">
                                            <h5 className="info-title">Brochure Content</h5>
                                            <p>Perfectly written content, integrated with great design for your brochures and additional marketing materials is all part of the My Stores and More package. Our professional copywriters will ensure that your message is communicated in a way which connects, and provide a consistent and appropriate tone of voice that builds trust, and creates calls to action with customers.</p>
                                        </div>
                                    </div>
                                    <div className="info info-horizontal">
                                        <div className="icon icon-success">
                                            <i className="now-ui-icons files_single-copy-04"></i>
                                        </div>
                                        <div className="description">
                                            <h4 className="info-title">Add Content</h4>
                                            <p>Your advertisement has one goal: to increase sales. My Stores and More copywriters are experts in writing using language that will connect your customer base with your product. Save yourself time and enjoy increased profits by employing the experts to get your message across.</p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            </Container>
                    </section>
                    <section className="features-6" style={{background: 'rgb(32, 39, 62)', padding: ' 50px 0', color: '#fff' }}>
                        <Container>
                            <Row>
                                <Col md={12} style={{marginTop: 50}}>
                                    <h3 style={{ textAlign: "center", color: "rgb(63, 223, 20)", fontWeight: 800 }}><span style={{color: '#fff', fontWeight: 300}}>Your Questions</span> Answered</h3>
                                </Col>
                                <Col md={6}>
                                    <Card className="card-plain">
                                        <CardHeader style={{color: "rgb(63, 223, 20)", fontSize: 18}} id="headingOne" role="tab">
                                        Why does content writing matter?
                                        </CardHeader> 
                                            <CardBody>
                                            What your website says is as important as how it looks when it comes to building customer engagement time, trust and conversions. You need to make sure that the content on your website is written in a manner that connects with your customer base, engages them and calls them to action. Do you want to be sure that you’re getting your message across in the best way possible?

</CardBody> 
                                    </Card>
                                    <Card className="card-plain">
                                        <CardHeader  style={{color: "rgb(63, 223, 20)", fontSize: 18}}  id="headingOne" role="tab">
                                        How will you be able to this for my company?
                                        </CardHeader> 
                                            <CardBody>
                                            Experience and research. We research your company, your industry, your competitors and your potential customers to give us the information we need. Our experience allows us to craft effective content that will call customers to action and improve your sales. We understand that different businesses are aimed at different customer bases. We understand that even within the same industry your business is different to your competitors; we craft content designed to make you stand out.


</CardBody> 
                                    </Card>
                                </Col>
                                <Col md={6}>
                                    <Card className="card-plain">
                                        <CardHeader   style={{color: "rgb(63, 223, 20)", fontSize: 18}}  id="headingOne" role="tab">
                                        Will professional content writing improve my sales?
                                               
                                        </CardHeader> 
                                            <CardBody> 
                                            Yes. Professionally written content, personalized to your brand is proven to improve sales and profits. We create a voice that is memorable, persuasive, and resonates with your customers, instilling who you are and what you do firmly in memories. Return visits to your website convert to increased sales. My Stores and More writers deliver content in a tone of voice that connects to your customers.


                                                </CardBody> 
                                    </Card>
                                    <Card className="card-plain">
                                        <CardHeader  style={{color: "rgb(63, 223, 20)", fontSize: 18}}  id="headingOne" role="tab">
                                        What are the other benefits of using My Stores and More Content Writing Services?
                                        </CardHeader> 
                                            <CardBody>
                                            Just like all of our services, you can be sure that you’re trusting this to the experts. Using My Stores and Mores Content Writing Services means that you can concentrate on what you do best – running your business - and we’ll take care of this for you. You have one less thing to worry about and your website can go live on time to do what it’s meant to do – earn you money.


                                            </CardBody> 
                                    </Card>
                                </Col> 
                            </Row>

                        </Container>
                    </section>
                    <Footer />
                </div>
            </div>
        </>
    )
}