import React from 'react'
import IndexNavbar from '../../../components/IndexNavbar' 
import { Container, Row, Col, Card, CardHeader,  CardBody } from 'reactstrap'
import {Helmet} from 'react-helmet'

import { Footer } from '../../../components/Footer'
import WebDesignHeader from './WebDesignHeader'
export type WebDesignProps = {}
export function WebDesign(props: WebDesignProps) {
    return (
        <>
        <Helmet>
        <title>Online Marketing | My Stores and More</title>
    <meta name="description" content="Complete Online Marketing and Brand Solutions"/>
        </Helmet>
            <IndexNavbar />
            <div className="wrapper">
                <WebDesignHeader /> 
                <div className="main">
                    <section style={{ background: 'rgb(32, 39, 62)', padding: '0 0 50px 0' }}>
                        <Container>
                            <Row>
                                <Col md={2}></Col>
                                <Col md={2}>
                                    <img src="/assets/images/logo-white.png" alt="" />
                                </Col>
                                <Col md={7}>
                                    <h5>Expose Your Business Online with a Stunning Website </h5>
                                    <p>Stylish and contemporary website design, right for both your business and your customers delivers excellent results and stops you from wasting money on ineffective marketing strategies. My Stores and More is your perfect partner for your complete online business solution.</p>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section className="features-6" style={{  padding: ' 50px 0', color: '#fff' }}>
                        <Container>

                            <Row>
                                <Col md={4}>
                                    <div className="info info-horizontal" style={{paddingTop: 0}}>
                                        <div className="icon icon-info">
                                            <i className="now-ui-icons design-2_html5"></i>
                                        </div>
                                        <div className="description">
                                            <h5 className="info-title">Web Design</h5>
                                            <p> Your website is the most visible face of your business. No matter what the size of your business, excellent website design reflects the unique character of what you do is one of the most efficient marketing tools available today. We produce contemporary, highly functional and visually stunning websites, guaranteed to grow your online presence and connect with your customer base.</p>
                                        </div>
                                    </div>
                                    <div className="info info-horizontal">
                                        <div className="icon icon-danger">
                                            <i className="now-ui-icons design_palette"></i>
                                        </div>
                                        <div className="description">
                                            <h4 className="info-title">Landing Pages </h4>
                                            <p>A landing page is built specifically for a marketing strategy or promotion. We’ll design stylish, engaging and user-friendly landing pages, integrated to your promotions that engage customers, calling them to action and increase your conversions. Wouldn’t it be great if you were able to actively engage your online audience with your latest great offer? That’s exactly what our landing pages will do for you.</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="phone-container">
                                        <img alt="..." src={"/assets/images/iphone2.08746fe1.png"}></img>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="info info-horizontal"  style={{paddingTop: 0}}>
                                        <div className="icon icon-primary">
                                            <i className="now-ui-icons design-2_ruler-pencil"></i>
                                        </div>
                                        <div className="description">
                                            <h5 className="info-title">Ecommerce Design </h5>
                                            <p>Ecommerce places your products and services at the fingertips of consumers, enabling you to sell more 24 hours a day. We work with you to design a customized ecommerce store, tailored to your individual needs that will transform how you do business in the online marketplace. Supported by one of the most powerful ecommerce software solutions the world has to offer, My Stores and More delivers bespoke and flexible online trading platforms that put you in the driving seat of your online business.
</p>
                                        </div>
                                    </div>
                                    <div className="info info-horizontal" >
                                        <div className="icon icon-success">
                                            <i className="now-ui-icons files_single-copy-04"></i>
                                        </div>
                                        <div className="description">
                                            <h4 className="info-title">App Design </h4>
                                            <p>Mobile apps are the fastest growing interface by which users access the online world. At My Stores and More our team of designers and coders will produce the perfect app for your business. This means you’ll literally be sitting on the smartphones and tablets of your potential customers and you’re just a click away. What better way is there to build brand loyalty and customer return rates?</p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            </Container>
                    </section>
                    <section className="features-6" style={{ background: 'rgb(32, 39, 62)', padding: ' 50px 0', color: '#fff' }}>
                        <Container>
                            <Row>
                                <Col md={12} style={{marginTop: 50}}>
                                    <h3 style={{ textAlign: "center", color: "rgb(63, 223, 20)", fontWeight: 800 }}><span style={{color: '#fff', fontWeight: 300}}>Your Questions</span> Answered</h3>
                                </Col>
                                <Col md={6}>
                                    <Card className="card-plain">
                                        <CardHeader style={{color: "rgb(63, 223, 20)", fontSize: 18}} id="headingOne" role="tab">
                                        Why is great design important?
                                        </CardHeader> 
                                            <CardBody>
                                            Stylish and contemporary website design, right for both your business and your customers delivers excellent results and stops you from wasting money on ineffective marketing strategies. A stunningly designed website will capture your customers attention and increase engagement time. Potential customers spend just seven seconds on 90% of the websites that they visit. How can you make sure that they stay on yours longer? Make sure that is deigned in a way that looks great and calls them to action to explore further. The longer they spend on your site the greater your chance of increased conversions and sales.

</CardBody> 
                                    </Card>
                                    
                                    <Card className="card-plain">
                                        <CardHeader   style={{color: "rgb(63, 223, 20)", fontSize: 18}}  id="headingOne" role="tab">
                                        How will my website stand out from the crowd?
                                               
                                        </CardHeader> 
                                            <CardBody>
                                            My Stores and More works with you to ensure that your website design conveys the perfect pitch for your customer base, letting them know instantly who you are, what you do, and why you are the people to trust to it best. Your company is special; your customers want to know that too. We’ll make sure your website stands out above your competitors.

</CardBody> 
                                    </Card>
                                </Col>
                                <Col md={6}>
                                   
                                    <Card className="card-plain">
                                        <CardHeader  style={{color: "rgb(63, 223, 20)", fontSize: 18}}  id="headingOne" role="tab">
                                        How can I encourage customers to explore my site?
                                            
                                        </CardHeader> 
                                            <CardBody>
                                            Making sure your website looks fantastic is the first step. We’ll make sure it’s also fully functional and packed with call to action features such as question leading hot buttons, to keep potential customers engaged and learning more about you. Your website will provide customers with a logical user experience, giving them the information they most want, building trust and improving conversion rates.

</CardBody> 
                                    </Card>
                                    <Card className="card-plain">
                                        <CardHeader  style={{color: "rgb(63, 223, 20)", fontSize: 18}}  id="headingOne" role="tab">
                                        Will my website look great, no matter where it’s seen?
                                        </CardHeader> 
                                            <CardBody>
                                            Yes. My Stores and More Websites are built with portability in mind and are fully responsive. This means that if customers are browsing on a desktop, laptop, tablet or smartphone, your website will still look great and be fully functional. Our designers work with our technical staff so that your website not only looks great but can be relied on to work just as well. My Stores and More is the trusted partner to design and build the ultimate website for your business.

</CardBody> 
                                    </Card>
                                </Col> 
                            </Row>

                        </Container>
                    </section> 
                    <Footer />
                </div>
            </div>
        </>
    )
}